import { useEffect, useState, useRef } from 'react';
import './leftsection.scss';
import { ConfigProvider, Switch, Tooltip, Checkbox } from 'antd';
import { useToast } from '../../../../config/hooks/useToast';
import useWindowDimensions from '../../../../config/hooks/useWindowDimentions';
//  <--REDUX--> //
import { setSelected } from '../../../../store/slices/property';
import { setSelectedUnits } from '../../../../store/slices/property-unit';
import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { PropertyStoreInterface } from '../../../../store/slices/property/property.interface';
import { UnitStoreInterface } from '../../../../store/slices/unit/unit.interface';
import {
    PropertyInterface,
    PropertyUnitInterface
} from '../../../../interface';
import LogoComponent from '../../../logo/logo';
import { Link } from 'react-router-dom';
import { lang } from '../../../langs';
import {
    EyeOutlined,
    CaretDownOutlined,
    CaretUpOutlined,
    CloseOutlined,
    SearchOutlined,
    FilterOutlined,
    EditOutlined
} from '@ant-design/icons';

interface Props {
    onClose?: any;
}

const LeftSection = ({ onClose }: Props) => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { ToastElement, toastManagerRef } = useToast();
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            layout: {
                main: {
                    leftsection: { welcomeMsg, listTitle }
                }
            }
        }
    } = lang;
    const dispatch = useAppDispatch();
    const { userData }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { propertyData }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const { unitData }: UnitStoreInterface = useAppSelector(
        state => state.unit
    );
    const [filteredProperties, setFilteredProperties] = useState<
        PropertyInterface[] | any[]
    >([]);
    const [searchInput, setSearchInput] = useState<string>('');
    const [selectedProperties, setSelectedProperties] = useState<
        PropertyInterface[]
    >([]);
    const [selectedPropertyUnits, setSelectedPropertyUnits] = useState<
        PropertyUnitInterface[]
    >([]);
    const [treeData, setTreeData] = useState<any>();
    const [filtersTreeData, setFiltersTreeData] = useState<any>();
    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [showReadOnlys, setShowReadOnlys] = useState<boolean>(false);
    const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [units, setUnits] = useState<any[]>([]);
    const [readOnlySelected, setReadOnlySelected] = useState<boolean>(false);
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [personalizedFilters, setPersonalizedFilters] =
        useState<boolean>(false);
    const filterButtonRef = useRef<HTMLButtonElement>(null);
    const filterDivRef = useRef<HTMLDivElement>(null);
    const showFilter = false;

    useEffect(() => {
        setIsMobile(width <= 768);
    }, [width]);

    useEffect(() => {
        if (showFilters && filterButtonRef.current && filterDivRef.current) {
            const buttonRect = filterButtonRef.current.getBoundingClientRect();
            filterDivRef.current.style.top = `${
                buttonRect.bottom + window.scrollY
            }px`;
            filterDivRef.current.style.left = `${
                buttonRect.left + window.scrollX - 25
            }px`;
        }
    }, [showFilters]);

    useEffect(() => {
        if (propertyData?.length > 0) {
            const updatedProperties = [...propertyData].map(property => ({
                ...property,
                propertyUnits: property?.propertyUnits
                    ?.slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
            }));
            const uniqueProperties = Array.from(
                new Set(updatedProperties.map(obj => JSON.stringify(obj)))
            ).map(str => JSON.parse(str) as PropertyInterface);
            setUnits(unitData);
            const mergedProperties = [...uniqueProperties, ...unitData];
            setFilteredProperties(
                [...mergedProperties].sort((a, b) =>
                    a.name.localeCompare(b.name)
                )
            );
        }
    }, [propertyData, unitData]);

    useEffect(() => {
        const tree = filteredProperties?.map(property => ({
            title: property.name,
            key: property.id,
            children: property.propertyUnits
                ?.filter(unit => unit.state === '1' || unit.state === 1)
                .map(unit => ({
                    title: unit.name,
                    key: unit.id,
                    type: 'unit'
                })),
            state: property.active,
            showChildren:
                property.showChildren || property.active === 'ReadOnly',
            partiallyChecked: false,
            type: 'property'
        }));
        setTreeData(tree);
        setFiltersTreeData(tree);
    }, [filteredProperties]);

    const reorderTree = () => {
        if (!selectedProperties || !selectedPropertyUnits || !treeData) return;
        const selectedPropertiesIds = selectedProperties.map(prop => prop.id);
        const selectedPropertyUnitsIds = selectedPropertyUnits.map(
            prop => prop.id
        );

        // Añadir la propiedad partiallyChecked a los padres según sea necesario
        const updatedTreeData = treeData.map(item => {
            const hasPartialCheckedChild = item.children?.some(child =>
                selectedPropertyUnitsIds.includes(child.key)
            );
            return {
                ...item,
                partiallyChecked: hasPartialCheckedChild || false
            };
        });

        // Obtener elementos seleccionados
        const selectedItems = updatedTreeData.filter(
            item =>
                selectedPropertiesIds.includes(item.key) ||
                item.partiallyChecked ||
                selectedPropertyUnitsIds.includes(item.key)
        );
        // Filtrar los elementos no seleccionados ni con hijos seleccionados
        const notSelected = updatedTreeData
            .filter(
                item =>
                    !selectedPropertiesIds.includes(item.key) &&
                    !item.children?.some(child =>
                        selectedPropertyUnitsIds.includes(child.key)
                    ) &&
                    !item.partiallyChecked &&
                    !selectedPropertyUnitsIds.includes(item.key)
            )
            .sort((a, b) => a.title.localeCompare(b.title));

        // Actualizar treeData con el nuevo orden
        setTreeData([...selectedItems, ...notSelected]);
    };

    useEffect(() => {
        dispatch(setSelected(selectedProperties));
        reorderTree();
    }, [selectedProperties]);

    useEffect(() => {
        dispatch(setSelectedUnits(selectedPropertyUnits));
        reorderTree();
    }, [selectedPropertyUnits]);

    /* const selectAll = () => {
        if(propertyData.length === checkedKeys.length) {
            setCheckedKeys([]);
            setSelectedProperties([]);
        } else {
            setSelectedProperties(propertyData);
            setCheckedKeys(propertyData.map(property => property.id));
        }
    } */

    const onCheck = element => {
        const key = element.key ?? element.id;
        const index = checkedKeys.indexOf(key);
        let updatedCheckedKeys;
        if (index !== -1) {
            updatedCheckedKeys = checkedKeys.filter(k => k !== key);
        } else {
            if (element.state === 'ReadOnly' || readOnlySelected) {
                updatedCheckedKeys = [key];
                setReadOnlySelected(!readOnlySelected);
            } else {
                updatedCheckedKeys = checkedKeys.concat(key);
            }
        }
        // Deseleccionar units si se selecciona un property
        if (element.type === 'property') {
            const propertyUnitsToDeselect =
                propertyData
                    .find(property => property.id === key)
                    ?.propertyUnits?.map(unit => unit.id) ?? [];
            updatedCheckedKeys = updatedCheckedKeys.filter(
                k => !propertyUnitsToDeselect.includes(k)
            );
        }
        // Deseleccionar property si se selecciona un unit
        if (element.type === 'unit') {
            const parentProperty = propertyData.find(property =>
                (property.propertyUnits || []).some(unit => unit.id === key)
            );
            if (parentProperty) {
                updatedCheckedKeys = updatedCheckedKeys.filter(
                    k => k !== parentProperty.id
                );
            }
        }
        setCheckedKeys(updatedCheckedKeys);
        const properties = propertyData.filter(property =>
            updatedCheckedKeys.includes(property.id)
        );
        const propertyUnits = propertyData.flatMap(property =>
            (property?.propertyUnits || []).filter(unit =>
                updatedCheckedKeys.includes(unit.id)
            )
        );
        const propertyUnits2 = units.filter(unit =>
            updatedCheckedKeys.includes(unit.id)
        );
        const mergedPropertyUnits = propertyUnits.concat(propertyUnits2);
        setSelectedProperties(properties);
        setSelectedPropertyUnits(mergedPropertyUnits);
    };

    const onSelect = element => {
        const key = element.key ?? element.id;
        const index = selectedKeys.indexOf(key);
        let updatedSelectedKeys;
        if (index !== -1) {
            updatedSelectedKeys = selectedKeys.filter(k => k !== key);
        } else {
            if (element.state === 'ReadOnly' || readOnlySelected) {
                updatedSelectedKeys = [key];
            } else {
                updatedSelectedKeys = selectedKeys.concat(key);
            }
        }
        setSelectedKeys(updatedSelectedKeys);
    };

    const handleChange = e => {
        e.preventDefault();
        const value = e.target.value;
        setSearchInput(value);
        if (value.length >= 1) {
            const filtered = propertyData
                .filter(property => {
                    const propertyMatches = property.name
                        .toLowerCase()
                        .includes(value.toLowerCase());
                    const unitMatches = property.propertyUnits.some(unit =>
                        unit.name.toLowerCase().includes(value.toLowerCase())
                    );
                    return propertyMatches || unitMatches;
                })
                .map(property => ({
                    ...property,
                    showChildren: true
                }));
            const filteredUnits = unitData.filter(unit =>
                unit.name.toLowerCase().includes(value.toLowerCase())
            );
            const mergedFiltered = [...filtered, ...filteredUnits];
            setFilteredProperties(
                [...mergedFiltered].sort((a, b) => a.name.localeCompare(b.name))
            );
        } else {
            const updatedProperties = [...propertyData].map(property => ({
                ...property,
                propertyUnits: property?.propertyUnits
                    ?.slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
            }));
            const uniqueProperties = Array.from(
                new Set(updatedProperties.map(obj => JSON.stringify(obj)))
            ).map(str => JSON.parse(str) as PropertyInterface);
            const mergedProperties = [...uniqueProperties, ...unitData];
            setFilteredProperties(
                [...mergedProperties].sort((a, b) =>
                    a.name.localeCompare(b.name)
                )
            );
        }
    };

    const CloseLeftSection = () => {
        onClose();
    };

    return (
        <>
            {' '}
            <ToastElement ref={toastManagerRef} />
            <div className='left-header'>
                <Link to='/panel' className='left-header__logo'>
                    <img
                        id='bannerImg'
                        className={`left-header__logo__img ${
                            userData?.clientData &&
                            userData.clientData.length > 0 &&
                            userData?.clientData[0].client?.image &&
                            'logo-contain'
                        }`}
                        src={`${
                            userData?.clientData &&
                            userData.clientData.length > 0 &&
                            userData?.clientData[0].client?.image
                                ? userData.clientData[0].client.image
                                : '/logos/client_logo.png'
                        }`}
                    />
                </Link>
            </div>
            <div className='misinmuebles'>
                <div className='misinmuebles__user'>
                    <span className='misinmuebles__user__name subtitle'>
                        {welcomeMsg} {userData?.name} {userData?.lastName}
                    </span>
                    <span className='misinmuebles__user__role subtitle'>
                        {userData?.roles[0].name}
                    </span>
                </div>
                <div className='misinmuebles__titleContainer'>
                    <p className='misinmuebles__titleContainer__title title'>
                        {' '}
                        {listTitle}
                    </p>
                    {userData.roles.some(role =>
                        role.name.includes('General')
                    ) && (
                        <div className='misinmuebles__titleContainer__switch'>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Switch: {
                                            colorPrimary: '#a2a2a3',
                                            colorTextTertiary: '#a2a2a3',
                                            colorText: '#C4C3C5',
                                            colorTextQuaternary: '#C4C3C5',
                                            colorPrimaryHover: '#a2a2a3'
                                        }
                                    }
                                }}
                            >
                                <Tooltip
                                    title={
                                        <span className='main_menu__grid-container__tooltip'>
                                            Haz click para mostrar/ocultar
                                            aquellos de solo visualización
                                        </span>
                                    }
                                    color='#fff'
                                >
                                    <Switch
                                        className='profile-body__edit-grid-container__item__edit-info-container__not__option__toggle'
                                        size='small'
                                        onChange={() =>
                                            setShowReadOnlys(!showReadOnlys)
                                        }
                                    />
                                </Tooltip>
                            </ConfigProvider>
                        </div>
                    )}
                </div>
                <div className='misinmuebles__listcontainer'>
                    <div className='misinmuebles__listcontainer__list'>
                        <div className='misinmuebles__listcontainer__list__search'>
                            <input
                                className='misinmuebles__listcontainer__list__search__searchbar subtitle'
                                type='text'
                                placeholder='Buscar'
                                value={searchInput}
                                onChange={handleChange}
                            ></input>
                            <span className='misinmuebles__listcontainer__list__search__icon'>
                                {showFilter && (
                                    <button
                                        className='misinmuebles__listcontainer__list__search__icon__img'
                                        ref={filterButtonRef}
                                        onClick={() => setShowFilters(true)}
                                    >
                                        <FilterOutlined rev={''} />
                                    </button>
                                )}
                                <SearchOutlined
                                    className='misinmuebles__listcontainer__list__search__icon__img'
                                    rev={''}
                                />
                            </span>
                        </div>
                        <div className='misinmuebles__listcontainer__list__inmuebleslist'>
                            {/* <div
                                className='settings__grid-container__item__list__element subtitle'
                            >
                                <div
                                    className={`settings__grid-container__item__list__element__data subtitle`}
                                    style={{ color: '#2994E6', fontWeight: 'bold' }}
                                >
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Checkbox: {
                                                    colorPrimary: `${
                                                        '#2994e6'
                                                    }`,
                                                    colorBorder: `${
                                                        '#2994e6'
                                                    }`,
                                                    colorPrimaryHover: `${
                                                        '#2994e6'
                                                    }`
                                                }
                                            }
                                        }}
                                    >
                                        <Checkbox
                                            className={`settings__grid-container__item__list__element__data__checkbox`}
                                            onClick={selectAll}
                                            checked={propertyData.length === checkedKeys.length}
                                        />
                                    </ConfigProvider>
                                    <div
                                        className={`settings__grid-container__item__list__element__data__text`}
                                    >
                                        Seleccionar todo
                                    </div>
                                </div>
                            </div> */}
                            {treeData?.map(item =>
                                item.state !== 'ReadOnly' || showReadOnlys ? (
                                    <div
                                        className='settings__grid-container__item__list__element subtitle'
                                        key={item.key}
                                    >
                                        <div
                                            className={`settings__grid-container__item__list__element__${
                                                item.state === 'ReadOnly'
                                                    ? 'readOnly'
                                                    : 'data'
                                            } subtitle`}
                                            key={item.key}
                                            style={{ color: '#2994E6' }}
                                        >
                                            <ConfigProvider
                                                theme={{
                                                    components: {
                                                        Checkbox: {
                                                            colorPrimary: `${
                                                                item.state ===
                                                                'ReadOnly'
                                                                    ? '#C4C3C5'
                                                                    : '#2994e6'
                                                            }`,
                                                            colorBorder: `${
                                                                item.state ===
                                                                'ReadOnly'
                                                                    ? '#C4C3C5'
                                                                    : '#2994e6'
                                                            }`,
                                                            colorPrimaryHover: `${
                                                                item.state ===
                                                                'ReadOnly'
                                                                    ? '#C4C3C5'
                                                                    : '#2994e6'
                                                            }`
                                                        }
                                                    }
                                                }}
                                            >
                                                <Checkbox
                                                    className={`settings__grid-container__item__list__element__${
                                                        item.state ===
                                                        'ReadOnly'
                                                            ? 'readOnly'
                                                            : 'data'
                                                    }__checkbox`}
                                                    onClick={() =>
                                                        onCheck(item)
                                                    }
                                                    checked={checkedKeys.includes(
                                                        item.key
                                                    )}
                                                    indeterminate={
                                                        item.partiallyChecked &&
                                                        !checkedKeys.includes(
                                                            item.key
                                                        )
                                                    }
                                                />
                                            </ConfigProvider>
                                            <div
                                                className={`settings__grid-container__item__list__element__${
                                                    item.state === 'ReadOnly'
                                                        ? 'readOnly'
                                                        : 'data'
                                                }__text`}
                                            >
                                                {item.title}
                                            </div>
                                            {item.state === 'ReadOnly' && (
                                                <span
                                                    className={`settings__grid-container__item__list__element__readOnly__logo`}
                                                >
                                                    <EyeOutlined rev={''} />
                                                </span>
                                            )}
                                            {item.children &&
                                                item.children.length > 0 && (
                                                    <button
                                                        className={`settings__grid-container__item__list__element__${
                                                            item.state ===
                                                            'ReadOnly'
                                                                ? 'readOnly'
                                                                : 'data'
                                                        }__dropdown`}
                                                        onClick={() =>
                                                            setTreeData(
                                                                prevItems =>
                                                                    prevItems.map(
                                                                        prevItem =>
                                                                            prevItem.key ===
                                                                            item.key
                                                                                ? {
                                                                                      ...prevItem,
                                                                                      showChildren:
                                                                                          !prevItem.showChildren
                                                                                  }
                                                                                : prevItem
                                                                    )
                                                            )
                                                        }
                                                    >
                                                        {item.showChildren ? (
                                                            <CaretUpOutlined
                                                                rev={''}
                                                            />
                                                        ) : (
                                                            <CaretDownOutlined
                                                                rev={''}
                                                            />
                                                        )}
                                                    </button>
                                                )}
                                        </div>
                                        {item.showChildren &&
                                            item.children &&
                                            item.children.length > 0 &&
                                            item.children.map(
                                                (child, index) => (
                                                    <div
                                                        className={`settings__grid-container__item__list__element__${
                                                            child.state ===
                                                            'ReadOnly'
                                                                ? 'readOnly'
                                                                : 'data'
                                                        } subtitle`}
                                                        style={{
                                                            marginLeft: '10%',
                                                            color: '#2994E6',
                                                            width: '95%'
                                                        }}
                                                        key={child.key}
                                                    >
                                                        <ConfigProvider
                                                            theme={{
                                                                components: {
                                                                    Checkbox: {
                                                                        colorPrimary:
                                                                            '#2994e6',
                                                                        colorBorder:
                                                                            '#2994e6',
                                                                        colorText:
                                                                            '#2994e6'
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            <Checkbox
                                                                className={`settings__grid-container__item__list__element__${
                                                                    item.state ===
                                                                    'ReadOnly'
                                                                        ? 'readOnly'
                                                                        : 'data'
                                                                }__checkbox`}
                                                                onClick={() =>
                                                                    onCheck(
                                                                        child
                                                                    )
                                                                }
                                                                checked={checkedKeys.includes(
                                                                    child.key
                                                                )}
                                                            />
                                                        </ConfigProvider>
                                                        <div
                                                            className={`settings__grid-container__item__list__element__${
                                                                child.state ===
                                                                'ReadOnly'
                                                                    ? 'readOnly'
                                                                    : 'data'
                                                            }__text`}
                                                        >
                                                            {child.title}
                                                        </div>
                                                        {child.state ===
                                                            'ReadOnly' && (
                                                            <EyeOutlined
                                                                rev={''}
                                                            />
                                                        )}
                                                    </div>
                                                )
                                            )}
                                    </div>
                                ) : (
                                    item.children &&
                                    item.children.length > 0 &&
                                    item.children.map(
                                        child =>
                                            child.state !== 'ReadOnly' && (
                                                <div
                                                    className='settings__grid-container__item__list__element subtitle'
                                                    key={child.key}
                                                    /* style={{
                                                        borderBottom:
                                                            '0.2px solid #2994E6'
                                                    }} */
                                                >
                                                    <div
                                                        className={`settings__grid-container__item__list__element__data subtitle`}
                                                        style={{
                                                            color: '#2994E6'
                                                        }}
                                                    >
                                                        <ConfigProvider
                                                            theme={{
                                                                components: {
                                                                    Checkbox: {
                                                                        colorPrimary:
                                                                            '#2994e6',
                                                                        colorBorder:
                                                                            '#2994e6',
                                                                        colorText:
                                                                            '#2994e6'
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            <Checkbox
                                                                className={`settings__grid-container__item__list__element__${
                                                                    item.state ===
                                                                    'ReadOnly'
                                                                        ? 'readOnly'
                                                                        : 'data'
                                                                }__checkbox`}
                                                                onClick={() =>
                                                                    onCheck(
                                                                        child
                                                                    )
                                                                }
                                                                checked={checkedKeys.includes(
                                                                    child.key
                                                                )}
                                                            />
                                                        </ConfigProvider>
                                                        <div
                                                            className={`settings__grid-container__item__list__element__data__text`}
                                                        >
                                                            {child.title}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    )
                                )
                            )}
                        </div>
                    </div>
                </div>
                <div className='misinmuebles__logo-container'>
                    {!isMobile ? (
                        <div className='misinmuebles__logo-container__logo-pryma'>
                            <Link to='/panel'>
                                <LogoComponent
                                    type='header'
                                    image='PRYMA_LOGO_VERDE.svg'
                                />
                            </Link>
                        </div>
                    ) : (
                        <div className='misinmuebles__logo-container__button'>
                            <button
                                className='button button-body--left-section'
                                onClick={CloseLeftSection}
                            >
                                Continuar
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {showFilters && (
                <div className='property-filters' ref={filterDivRef}>
                    <button
                        className='property-filters__header title'
                        onClick={() => setShowFilters(false)}
                    >
                        <CloseOutlined rev={''} />
                    </button>
                    {!personalizedFilters ? (
                        <>
                            <div className='property-filters__title title'>
                                Configuración de búsqueda personalizada
                            </div>
                            <div className='property-filters__subtitle subtitle'>
                                Selecciona rápidamente tus Inmuebles/Unidades
                                utilizando estos botones personalizados.
                            </div>
                            <div className='property-filters__section subtitle'>
                                <div className='property-filters__section__title subtitle'>
                                    Filtros predeterminados
                                </div>
                                <div className='property-filters__section__content subtitle'>
                                    <button className='property-filters__section__content__item'>
                                        Todos los inmuebles
                                    </button>
                                    <button className='property-filters__section__content__item'>
                                        Todas las unidades
                                    </button>
                                    <button className='property-filters__section__content__item'>
                                        Todo
                                    </button>
                                </div>
                            </div>
                            <div className='property-filters__section subtitle'>
                                <div className='property-filters__section__title subtitle'>
                                    Filtros personalizados
                                    <button
                                        className='property-filters__section__title__icon'
                                        onClick={() =>
                                            setPersonalizedFilters(true)
                                        }
                                    >
                                        <EditOutlined rev={''} />
                                    </button>
                                </div>
                                <div className='property-filters__section__content subtitle'>
                                    <button className='property-filters__section__content__item'>
                                        Todos los inmuebles
                                    </button>
                                    <button className='property-filters__section__content__item'>
                                        Todas las unidades
                                    </button>
                                    <button className='property-filters__section__content__item'>
                                        Todo
                                    </button>
                                </div>
                            </div>
                            <div className='property-filters__footer subtitle'>
                                <div className='property-filters__footer__text'>
                                    Guarda la selección actual de tus
                                    Inmuebles/Unidades presionando el +
                                </div>
                                <button className='property-filters__footer__button bigtitle'>
                                    +
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='property-filters__title title'>
                                Nuevo filtro personalizado
                            </div>
                            <div className='property-filters__subtitle subtitle'>
                                Guarda tu selección actual rellenando los datos
                                y haciendo click en Guardar.
                            </div>
                            <div className='property-filters__section subtitle'>
                                <div className='property-filters__section__title subtitle'>
                                    Nombre del filtro
                                </div>
                                <input
                                    className='property-filters__section__input subsubtitle'
                                    placeholder='Ingresa el nombre del filtro aquí'
                                ></input>
                            </div>
                            <div className='property-filters__section subtitle'>
                                <div className='property-filters__section__title subtitle'>
                                    Resumen de inmuebles/unidades seleccionadas
                                </div>
                                <div className='property-filters__section__list subtitle'>
                                    {filtersTreeData?.map(item =>
                                        item.state !== 'ReadOnly' ||
                                        showReadOnlys ? (
                                            <div
                                                className='settings__grid-container__item__list__element subtitle'
                                                key={item.key}
                                            >
                                                <div
                                                    className={`settings__grid-container__item__list__element__${
                                                        item.state ===
                                                        'ReadOnly'
                                                            ? 'readOnly'
                                                            : 'data'
                                                    } subtitle`}
                                                    key={item.key}
                                                    style={{ color: '#2994E6' }}
                                                >
                                                    <ConfigProvider
                                                        theme={{
                                                            components: {
                                                                Checkbox: {
                                                                    colorPrimary: `${
                                                                        item.state ===
                                                                        'ReadOnly'
                                                                            ? '#C4C3C5'
                                                                            : '#2994e6'
                                                                    }`,
                                                                    colorBorder: `${
                                                                        item.state ===
                                                                        'ReadOnly'
                                                                            ? '#C4C3C5'
                                                                            : '#2994e6'
                                                                    }`,
                                                                    colorPrimaryHover: `${
                                                                        item.state ===
                                                                        'ReadOnly'
                                                                            ? '#C4C3C5'
                                                                            : '#2994e6'
                                                                    }`
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <Checkbox
                                                            className={`settings__grid-container__item__list__element__${
                                                                item.state ===
                                                                'ReadOnly'
                                                                    ? 'readOnly'
                                                                    : 'data'
                                                            }__checkbox`}
                                                            onClick={() =>
                                                                onSelect(item)
                                                            }
                                                            checked={selectedKeys.includes(
                                                                item.key
                                                            )}
                                                        />
                                                    </ConfigProvider>
                                                    <div
                                                        className={`settings__grid-container__item__list__element__${
                                                            item.state ===
                                                            'ReadOnly'
                                                                ? 'readOnly'
                                                                : 'data'
                                                        }__text`}
                                                    >
                                                        {item.title}
                                                    </div>
                                                    {item.state ===
                                                        'ReadOnly' && (
                                                        <span
                                                            className={`settings__grid-container__item__list__element__readOnly__logo`}
                                                        >
                                                            <EyeOutlined
                                                                rev={''}
                                                            />
                                                        </span>
                                                    )}
                                                    {item.children &&
                                                        item.children.length >
                                                            0 && (
                                                            <button
                                                                className={`settings__grid-container__item__list__element__${
                                                                    item.state ===
                                                                    'ReadOnly'
                                                                        ? 'readOnly'
                                                                        : 'data'
                                                                }__dropdown`}
                                                                onClick={() =>
                                                                    setFiltersTreeData(
                                                                        prevItems =>
                                                                            prevItems.map(
                                                                                prevItem =>
                                                                                    prevItem.key ===
                                                                                    item.key
                                                                                        ? {
                                                                                              ...prevItem,
                                                                                              showChildren:
                                                                                                  !prevItem.showChildren
                                                                                          }
                                                                                        : prevItem
                                                                            )
                                                                    )
                                                                }
                                                            >
                                                                {item.showChildren ? (
                                                                    <CaretUpOutlined
                                                                        rev={''}
                                                                    />
                                                                ) : (
                                                                    <CaretDownOutlined
                                                                        rev={''}
                                                                    />
                                                                )}
                                                            </button>
                                                        )}
                                                </div>
                                                {item.showChildren &&
                                                    item.children &&
                                                    item.children.length > 0 &&
                                                    item.children.map(
                                                        (child, index) => (
                                                            <div
                                                                className={`settings__grid-container__item__list__element__${
                                                                    child.state ===
                                                                    'ReadOnly'
                                                                        ? 'readOnly'
                                                                        : 'data'
                                                                } subtitle`}
                                                                style={{
                                                                    marginLeft:
                                                                        '10%',
                                                                    color: '#2994E6',
                                                                    width: '95%'
                                                                }}
                                                                key={child.key}
                                                            >
                                                                <ConfigProvider
                                                                    theme={{
                                                                        components:
                                                                            {
                                                                                Checkbox:
                                                                                    {
                                                                                        colorPrimary:
                                                                                            '#2994e6',
                                                                                        colorBorder:
                                                                                            '#2994e6',
                                                                                        colorText:
                                                                                            '#2994e6'
                                                                                    }
                                                                            }
                                                                    }}
                                                                >
                                                                    <Checkbox
                                                                        className={`settings__grid-container__item__list__element__${
                                                                            item.state ===
                                                                            'ReadOnly'
                                                                                ? 'readOnly'
                                                                                : 'data'
                                                                        }__checkbox`}
                                                                        onClick={() =>
                                                                            onSelect(
                                                                                child
                                                                            )
                                                                        }
                                                                        checked={selectedKeys.includes(
                                                                            child.key
                                                                        )}
                                                                    />
                                                                </ConfigProvider>
                                                                <div
                                                                    className={`settings__grid-container__item__list__element__${
                                                                        child.state ===
                                                                        'ReadOnly'
                                                                            ? 'readOnly'
                                                                            : 'data'
                                                                    }__text`}
                                                                >
                                                                    {
                                                                        child.title
                                                                    }
                                                                </div>
                                                                {child.state ===
                                                                    'ReadOnly' && (
                                                                    <EyeOutlined
                                                                        rev={''}
                                                                    />
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                        ) : (
                                            item.children &&
                                            item.children.length > 0 &&
                                            item.children.map(
                                                child =>
                                                    child.state !==
                                                        'ReadOnly' && (
                                                        <div
                                                            className='settings__grid-container__item__list__element subtitle'
                                                            key={child.key}
                                                            /* style={{
                                                            borderBottom:
                                                                '0.2px solid #2994E6'
                                                        }} */
                                                        >
                                                            <div
                                                                className={`settings__grid-container__item__list__element__data subtitle`}
                                                                style={{
                                                                    color: '#2994E6'
                                                                }}
                                                            >
                                                                <ConfigProvider
                                                                    theme={{
                                                                        components:
                                                                            {
                                                                                Checkbox:
                                                                                    {
                                                                                        colorPrimary:
                                                                                            '#2994e6',
                                                                                        colorBorder:
                                                                                            '#2994e6',
                                                                                        colorText:
                                                                                            '#2994e6'
                                                                                    }
                                                                            }
                                                                    }}
                                                                >
                                                                    <Checkbox
                                                                        className={`settings__grid-container__item__list__element__${
                                                                            item.state ===
                                                                            'ReadOnly'
                                                                                ? 'readOnly'
                                                                                : 'data'
                                                                        }__checkbox`}
                                                                        onClick={() =>
                                                                            onSelect(
                                                                                child
                                                                            )
                                                                        }
                                                                        checked={selectedKeys.includes(
                                                                            child.key
                                                                        )}
                                                                    />
                                                                </ConfigProvider>
                                                                <div
                                                                    className={`settings__grid-container__item__list__element__data__text`}
                                                                >
                                                                    {
                                                                        child.title
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                            )
                                        )
                                    )}
                                </div>
                            </div>
                            <div className='property-filters__footer subtitle'>
                                <button
                                    className='property-filters__footer__cancel-btn button'
                                    onClick={() =>
                                        setPersonalizedFilters(false)
                                    }
                                >
                                    Cancelar
                                </button>
                                <button className='property-filters__footer__save-btn button'>
                                    Guardar
                                </button>
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default LeftSection;
