//  <--COMPONENTS--> //
import { useEffect, useState } from 'react';
import SubNavBar from '../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../components/header/header';
import { DatePicker, ConfigProvider /* , Checkbox */ } from 'antd';
import dayjs from 'dayjs';
//  <--REDUX--> //
import { useAppSelector } from '../../../store/hooks';
import { PropertyStoreInterface } from '../../../store/slices/property/property.interface';
import { PropertyUnitStoreInterface } from '../../../store/slices/property-unit/property-unit.interface';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import useWindowDimensions from '../../../config/hooks/useWindowDimentions';
import './garbage-control.scss';
import { useCallApi } from '../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../config/hooks/useCallApi/constants';
import { FormatLabels } from '../../../config/utils/IdbFormatData';
import { StackedBarChart } from '../../../components/charts';
import { IDB } from '../../../config/utils/IndexedDb';
import { lang } from '../../langs';
import { Zfill } from '../../../config/utils/Utilities';
import ChartFormated from '../../../components/dataCenterComponents/garbageControl/components/chartFormated';
import GarbageCostsTable from './components/garbage-costs-table';
import { ApiDownloadGarbageProratio } from '../../../config/service';

const dbName = process.env.REACT_APP_PUBLIC_INDEXEDDB ?? 'pryma';
const tbl2name = 'garbage-control';

const Stacked1 = StackedBarChart;
Stacked1.Config.plugins.legend.display = false;
Stacked1.Config.plugins.legend.position = 'top';
Stacked1.Config.plugins.title.text = '';

const { RangePicker } = DatePicker;

interface LabelInterface {
    date: string;
}

const GarbageControl = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            pageDataCenter: {
                months,
                pageGarbageControl: { title, dateRangeTitle }
            }
        }
    } = lang;
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        /* AddDataToDb, */
        GetAllDataDb, // this function triggers an effect that updates the data, the parameter that brings the data is ObtainedData
        GetDataDb, // this function triggers an effect that updates the data, the parameter that brings the data is ObtainedData
        ObtainedData
    } = IDB(dbName);
    const { selected }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const { selectedUnits }: PropertyUnitStoreInterface = useAppSelector(
        state => state.propertyUnit
    );

    const [labels, setLabels] = useState<string[]>([]);
    const [dateFilterRange, setDateFilterRange] = useState<string[]>(['']);
    const [labelList, setLabelList] = useState<LabelInterface[]>([]);
    const [defaultStartDate, setDefaultStartDate] = useState<any>();
    const [defaultEndDate, setDefaultEndDate] = useState<any>();
    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [formattedLabels, setFormattedLabels] = useState<string[]>([]);
    const [garbageData, setGarbageData] = useState<any[]>([]);
    const [selectedLabels, setSelectedLabels] = useState<any[]>([]);
    const [downloadGarbageCost, setDownloadGarbageCost] = useState<string>('');
    const [downloadDate, setDownloadDate] = useState<string>('yyyymm');
    const [showCosts, setShowCosts] = useState<boolean>(false);
    const [showDetails, setShowDetails] = useState<boolean>(false);

    useEffect(() => {
        setIsMobile(width <= 768);
    }, [width]);

    useEffect(() => {
        setSelectedLabels(labels);
    }, [labels]);

    useEffect(() => {
        const idsFromSelected = selected.flatMap(item => {
            return item.propertyUnits.map(unit => unit.id);
        });
        const idsFromSelectedUnits = selectedUnits.map(item => item.id);
        const idsUnits = [...idsFromSelected, ...idsFromSelectedUnits];
        const finalFilter = {
            tables: [tbl2name],
            filters: {
                keys: ['propertyId'],
                valuesProperty: [],
                valuesPropertyUnits: idsUnits
            }
        };
        if (labels.length > 0) {
            if (selectedUnits.length + selected.length >= 1) {
                GetDataDb({ ...finalFilter, dateFilterRange });
            } else {
                GetAllDataDb({
                    tables: [tbl2name],
                    filters: {
                        keys: [],
                        valuesProperty: []
                    },
                    dateFilterRange
                });
            }
        }
    }, [labels, selected, selectedUnits, dateFilterRange]);

    useEffect(() => {
        if (ObtainedData['garbage-control']) {
            setGarbageData(ObtainedData['garbage-control']);
        }
        formatLabelsShow(selectedLabels);
    }, [ObtainedData]);

    useEffect(() => {
        CallData();
        if (labels.length > 0) {
            const startDate = dayjs(
                `${labels[0].slice(0, 4)}-${labels[0].slice(4, 6)}-01`
            );
            const endDate = dayjs(
                `${labels[labels.length - 1].slice(0, 4)}-${labels[
                    labels.length - 1
                ].slice(4, 6)}-01`
            ).endOf('month');
            setDefaultStartDate(startDate);
            setDefaultEndDate(endDate);
        }
    }, []);

    const CallData = async () => {
        // GET FILTERS

        // GET CHARTS DATA‹
        const labelsData = localStorage.getItem('labels');
        const labels = labelsData ? JSON.parse(labelsData) : null;
        const arrayLabels = FormatLabels(labels, 'date');
        setLabelList(labels);
        setLabels(arrayLabels);
        GetAllDataDb({
            tables: [tbl2name],
            filters: {
                keys: [],
                valuesProperty: []
            },
            dateFilterRange
        });
    };

    const ChangeDate = date => {
        const ini = `${date[0].$y}${Zfill(parseInt(date[0].$M) + 1, 2)}`;
        const fin = `${date[1].$y}${Zfill(parseInt(date[1].$M) + 1, 2)}`;
        // eslint-disable-next-line array-callback-return
        const newBreakpoints = labelList
            .map(item => {
                if (item.date >= ini && item.date <= fin) {
                    return item.date;
                } else {
                    return '';
                }
            })
            .filter(el => el !== '');
        // FORMATEAR DATA NUEVAMENTE EN BASE A LAS FECHAS SELECCIOANDAS
        setSelectedLabels(newBreakpoints);
        setDateFilterRange([ini, fin]);
    };

    useEffect(() => {
        if (labels.length > 0) {
            const startDate = dayjs(
                `${labels[0].slice(0, 4)}-${labels[0].slice(4, 6)}-01`
            );
            const endDate = dayjs(
                `${labels[labels.length - 1].slice(0, 4)}-${labels[
                    labels.length - 1
                ].slice(4, 6)}-01`
            ).endOf('month');
            setDefaultStartDate(startDate);
            setDefaultEndDate(endDate);
        }
    }, [labels]);

    const formatLabelsShow = labelsArray => {
        const formatted = labelsArray.map(label => {
            const year = label.substring(0, 4);
            const month = label.substring(4, 6);
            return `${months[parseInt(month) - 1].slice(0, 3)} ${year.slice(
                2,
                4
            )}`;
        });
        setFormattedLabels(formatted);
    };

    const GetParams = async (garbageCost, date, showCosts) => {
        setDownloadGarbageCost(garbageCost);
        setDownloadDate(date);
        setShowCosts(showCosts);
    };

    const DownloadGarbageProratios = async () => {
        const values = {
            date: `${downloadDate.slice(0, 4)}-${downloadDate.slice(4)}`,
            garbageCost: downloadGarbageCost,
            propertyUnits: selectedUnits.map(item => item.id)
        };
        const response = await GetData(
            ApiDownloadGarbageProratio,
            HTTP_METHODS.POST,
            values
        );
        const {
            data: { filepathdownload }
        } = response;
        window.open(filepathdownload, '_blank');
    };

    return (
        <>
            <SubNavBar></SubNavBar>
            <div className='garbagecontrol'>
                <Header title={{ name: title }} />
                <div className='garbagecontrol__chart-container'>
                    <div className='garbagecontrol__chart-container__filters'>
                        {/* <div className='garbagecontrol__chart-container__filters__bags'>
                            <img
                                className={`garbagecontrol__chart-container__filters__bags__logo`}
                                src={`/svg-icons/Garbage.svg`}
                                alt='g'
                                key='garbage'
                            />
                        </div> */}
                        <div className='garbagecontrol__chart-container__filters__date-filter'>
                            <div className='garbagecontrol__chart-container__filters__date-filter__title subtitle'>
                                {dateRangeTitle}
                            </div>
                            {defaultStartDate && (
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            DatePicker: {
                                                colorLink: '#a2a2a3',
                                                colorLinkActive: '#a2a2a3',
                                                colorPrimary: '#a2a2a3',
                                                colorPrimaryBorder: '#a2a2a3',
                                                colorPrimaryHover: '#a2a2a3',
                                                colorBgContainer: '#f3f2f5',
                                                colorBorder: '#fff',
                                                fontFamily: 'Roboto',
                                                colorIcon: '#a2a2a3',
                                                colorText: '#C4C3C5',
                                                colorTextHeading: '#a2a2a3',
                                                colorTextPlaceholder: '#a2a2a3'
                                            }
                                        }
                                    }}
                                >
                                    <RangePicker
                                        className='garbagecontrol__chart-container__filters__date-filter__date-picker'
                                        picker='month'
                                        format={'YYYY-MM'}
                                        onChange={ChangeDate}
                                        defaultValue={[
                                            defaultStartDate,
                                            defaultEndDate
                                        ]}
                                    />
                                </ConfigProvider>
                            )}
                            {LoadingData && <LoaderElement />}
                        </div>
                    </div>
                    <div className='garbagecontrol__chart-container__chart'>
                        <ChartFormated
                            data={garbageData}
                            formattedLabels={formattedLabels}
                            labels={selectedLabels}
                        />
                    </div>
                </div>
                <div className='garbagecontrol__costs'>
                    <div className='garbagecontrol__costs__title title'>
                        Control costos y entrega de bolsas
                    </div>
                    <div className='garbagecontrol__costs__subtitle subtitle'>
                        Periodo: mensual
                    </div>
                    {isMobile && <div></div>}
                    <GarbageCostsTable
                        onChange={GetParams}
                        showDetails={showDetails}
                    />
                </div>
                <div className='garbagecontrol__buttons'>
                    <button
                        type='button'
                        className={`button button-body--download-garbage-control subtitle ${
                            !showCosts && 'disabled'
                        }`}
                        onClick={() => setShowDetails(!showDetails)}
                    >
                        {showDetails ? 'Ocultar detalle' : 'Ver detalle'}
                    </button>
                    <button
                        type='button'
                        className={`button button-body--download-garbage-control subtitle ${
                            !showCosts && 'disabled'
                        }`}
                        onClick={DownloadGarbageProratios}
                    >
                        Descargar costos
                    </button>
                </div>
            </div>
        </>
    );
};

export default GarbageControl;
