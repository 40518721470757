import { useEffect, useState } from 'react';
import DetailsComponent from '../../details/details';
import LeftSection from './leftsection/leftsection';
import RightSection from './rightsection/rightsection';
import BodySection from './body/body';
import { useAppSelector } from '../../../store/hooks';
import { useToast } from '../../../config/hooks/useToast';
import useWindowDimensions from '../../../config/hooks/useWindowDimentions';
import { useToastHook } from '../../../store/slices/toast/toast.hook';
import './main-private-style.scss';
import IdleTimer from '../../idle/idle-timer';
import { appCookie, Logout } from '../../../config/utils/Auth';
import { RemoveCookie } from '../../../config/utils/Cookie';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { lang } from './langs';
import { Tooltip } from 'antd';

const Main = ({ children }: any) => {
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { ToastConfig } = useToastHook();
    const { show, toastData } = useAppSelector(state => state.toast);
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: { inactivityMessage }
    } = lang;
    const [openButtons, setOpenButtons] = useState<boolean>(false);
    const [leftSectionOpen, setLeftSectionOpen] = useState<boolean>(false);
    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [withLeftSection, setWithLeftSection] = useState<boolean>(false);

    const OpenButtons = () => {
        setOpenButtons(!openButtons);
    };

    useEffect(() => {
        if (show) {
            Alerts(toastData.message, toastData.color);
        }
    }, [show]);

    const Alerts = (message, color) => {
        ConfigToast.text = message;
        ConfigToast.backColor = color;
        setConfigToast(ConfigToast);
    };

    const OnLogout = async () => {
        RemoveCookie(appCookie);
        RemoveCookie(process.env.REACT_APP_PUBLIC_USER_DATA_COOKIE);
        setTimeout(() => {
            Logout();
        }, 100);
    };

    const LogoutIddleTimer = () => {
        ToastConfig({
            message: inactivityMessage,
            color: Colors.Warning
        });
    };

    useEffect(() => {
        setIsMobile(width <= 768);
    }, [width]);

    useEffect(() => {
        const currentPath = window.location.pathname;
        if (
            !isMobile &&
            (currentPath.includes('data-center') ||
                currentPath.includes('evolution') ||
                currentPath.includes('ranking') ||
                currentPath.includes('garbage-control') ||
                currentPath.includes('control-panel'))
        ) {
            setLeftSectionOpen(true);
            setWithLeftSection(true);
        } else {
            setLeftSectionOpen(false);
            setWithLeftSection(false);
        }
    }, [window.location.pathname]);

    return (
        <IdleTimer handler={OnLogout} onMessage={LogoutIddleTimer}>
            <main>
                <ToastElement ref={toastManagerRef} />
                <div className='pryma-container'>
                    {isMobile && (
                        <div
                            className={`pryma-container__left-section${
                                leftSectionOpen ? '__open' : ''
                            }`}
                        >
                            <DetailsComponent
                                showSection={isMobile && leftSectionOpen}
                                place='left'
                            >
                                <LeftSection
                                    onClose={() =>
                                        setLeftSectionOpen(!leftSectionOpen)
                                    }
                                />
                            </DetailsComponent>
                        </div>
                    )}
                    {!isMobile && leftSectionOpen ? (
                        <div className='pryma-container__left-section'>
                            <LeftSection />
                            {!withLeftSection && (
                                <button
                                    onClick={() =>
                                        setLeftSectionOpen(!leftSectionOpen)
                                    }
                                    className='pryma-container__left-section__button'
                                >
                                    <div className='pryma-container__left-section__button__lines'></div>
                                </button>
                            )}
                        </div>
                    ) : (
                        <div className='pryma-container__reduce-left-section'>
                            <button
                                onClick={() =>
                                    setLeftSectionOpen(!leftSectionOpen)
                                }
                                className='pryma-container__reduce-left-section__button'
                            >
                                <div className='pryma-container__reduce-left-section__button__lines'></div>
                            </button>
                            <div className='pryma-container__reduce-left-section__logo'>
                                <img src={`/svg-icons/PrymaMini.svg`} />
                            </div>
                        </div>
                    )}
                    <div className='pryma-container__main-content'>
                        <BodySection>{children}</BodySection>
                    </div>
                    <div className='pryma-container__right-section'>
                        <RightSection />
                    </div>
                </div>
                {isMobile && (
                    <div className='options_button__item options_button__item__main-left left-section'>
                        <button
                            className={
                                openButtons
                                    ? 'options_button__item__button __button-left open'
                                    : 'options_button__item__button __button-left closed'
                            }
                            onClick={() => setLeftSectionOpen(!leftSectionOpen)}
                        >
                            <img
                                className='options_button__item__button__img'
                                src={`/svg-icons/settings/PropertyIconW.svg`}
                            />
                        </button>
                    </div>
                )}
                <div className='options_button'>
                    {openButtons && (
                        <>
                            <div className='options_button__item options_button__item__top'>
                                <button
                                    className='options_button__item__button __button subtitle'
                                    disabled
                                >
                                    New <br /> Requeriment
                                </button>
                            </div>
                            <div className='options_button__item options_button__item__left'>
                                <button
                                    className='options_button__item__button __button subtitle'
                                    disabled
                                >
                                    Input <br /> Data
                                </button>
                            </div>
                        </>
                    )}
                    <Tooltip
                        title={
                            <span className='main_menu__grid-container__tooltip'>
                                PRÓXIMAMENTE
                            </span>
                        }
                        color='#fff'
                    >
                        <div className='options_button__item options_button__item__main'>
                            <button
                                className={
                                    openButtons
                                        ? 'options_button__item__button __button open bigtitle disabled'
                                        : 'options_button__item__button __button closed bigtitle disabled'
                                }
                                onClick={OpenButtons}
                            >
                                {openButtons ? '-' : '+'}
                            </button>
                        </div>
                    </Tooltip>
                </div>
            </main>
        </IdleTimer>
    );
};

export default Main;
