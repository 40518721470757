import './rankingCard.scss';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { lang } from '../../langs';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';
import { GetStorage } from '../../../config/utils/Storage';

interface Props {
    selectedProperties: any[];
    selectedUnits: any[];
    gestor: boolean;
}

interface ShowDataItem {
    BZeroIndexMonth: string;
    BZeroIndexTrim: number;
    OrganicMonth: string;
    OrganicTrim: string;
    RecyclableMonth: string;
    RecyclableTrim: string;
    TrashMonth: string;
    TrashTrim: string;
    FullfilmentMonth: number;
    FullfilmentTrashMonth: number;
    RecollectionTrim: number;
    property: string | null;
    propertyName: string | null;
    propertyUnit: string | null;
    propertyUnitName: string | null;
}

const deffaultRole =
    parseInt(GetStorage(process.env.REACT_APP_PUBLIC_DEFFAULT_ROLE)) || 0;

const RankingCard = ({ selectedProperties, selectedUnits, gestor }: Props) => {
    const { preferences, userData }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { roles } = userData;
    const { appSections } = roles[deffaultRole];
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            dataCenterComponents: {
                rankingCard: { title, subtitle, detailTitle }
            }
        }
    } = lang;
    const [showData, setShowData] = useState<ShowDataItem[]>([]);
    const navigate = useNavigate();

    // Segundo useEffect para actualizar showData basado en dataFormated
    useEffect(() => {
        const metrics = localStorage.getItem('metrics');
        const metricsArray = metrics ? JSON.parse(metrics) : [];
        if (gestor) {
            const metricsRecollectionTrim = metricsArray?.ranking?.map(
                item => ({
                    ...item,
                    RecollectionTrim: parseFloat(item.RecollectionTrim)
                })
            );

            const sortedMetrics = metricsRecollectionTrim?.sort(
                (a, b) => b.RecollectionTrim - a.RecollectionTrim
            );

            if (selectedProperties.length + selectedUnits.length > 0) {
                const filteredMetrics = sortedMetrics.filter(
                    metric =>
                        selectedProperties.some(
                            prop => prop.id === metric.property
                        ) ||
                        selectedUnits.some(
                            unit => unit.id === metric.propertyUnit
                        )
                );
                setShowData(filteredMetrics);
            } else {
                setShowData(sortedMetrics);
            }
        } else {
            const metricsWithFloatBZeroIndex = metricsArray?.ranking?.map(
                item => ({
                    ...item,
                    BZeroIndexTrim: parseFloat(item.BZeroIndexTrim) * 100
                })
            );
            const validBZeroIndex = metricsWithFloatBZeroIndex?.filter(
                item => item.BZeroIndexTrim > 0
            );
            if (validBZeroIndex?.length > 0) {
                const sortedMetrics = [...validBZeroIndex].sort(
                    (a, b) => a.BZeroIndexTrim - b.BZeroIndexTrim
                );
                if (selectedProperties.length + selectedUnits.length > 0) {
                    const filteredMetrics = sortedMetrics.filter(
                        metric =>
                            selectedProperties.some(
                                prop => prop.id === metric.property
                            ) ||
                            selectedUnits.some(
                                unit => unit.id === metric.propertyUnit
                            )
                    );
                    setShowData(filteredMetrics);
                } else {
                    setShowData(sortedMetrics);
                }
            }
        }
    }, [selectedProperties, selectedUnits]);

    const formatRecollectionTrim = value => {
        if (value >= 1000000) {
            // 1,000,000 gramos = 1 tonelada
            return `${(value / 1000000).toLocaleString('es-CL', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 1
            })} ton`;
        } else if (value >= 1000 && value < 1000000) {
            // 1,000 gramos = 1 kilogramo
            return `${(value / 1000).toLocaleString('es-CL', {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
            })} kg`;
        } else {
            return `${value.toLocaleString('es-CL', {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
            })} g`;
        }
    };

    return (
        <div className='ranking-card'>
            <div className='ranking-card__titulo-card title'>
                Top 10 {title}
            </div>
            <div className='ranking-card__subtitulo-card subtitle'>
                {subtitle}
            </div>
            <div className='ranking-card__content-container'>
                <div className='ranking-card__content-container__content'>
                    <div className='ranking-card__content-container__content__podio-container'>
                        <div className='ranking-card__content-container__content__podio-container__item'>
                            <img
                                className='ranking-card__content-container__content__podio-container__item__img'
                                src='/svg-icons/SecondPlaceIcon.svg'
                            />
                            <div className='ranking-card__content-container__content__podio-container__item__name subsubtitle'>
                                {showData?.[1]?.propertyName ??
                                    showData?.[1]?.propertyUnitName ??
                                    ''}
                            </div>
                            <div className='ranking-card__content-container__content__podio-container__item__value'>
                                <div className='ranking-card__content-container__content__podio-container__item__value__number title'>
                                    #2
                                </div>
                                <div className='ranking-card__content-container__content__podio-container__item__value__percentage subsubtitle'>
                                    {gestor ? (
                                        formatRecollectionTrim(
                                            showData?.[1]?.RecollectionTrim || 0
                                        )
                                    ) : (
                                        <>
                                            {showData?.[1]?.BZeroIndexTrim?.toLocaleString(
                                                'es-CL',
                                                {
                                                    minimumFractionDigits: 1,
                                                    maximumFractionDigits: 1
                                                }
                                            ) || ''}
                                            %
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='ranking-card__content-container__content__podio-container__item firstPlace'>
                            <img
                                className='ranking-card__content-container__content__podio-container__item__img'
                                src='/svg-icons/FirstPlaceIcon.svg'
                            />
                            <div className='ranking-card__content-container__content__podio-container__item__name subsubtitle'>
                                {(showData.length > 1 &&
                                    showData?.[0]?.propertyName) ??
                                    showData?.[0]?.propertyUnitName ??
                                    ''}
                            </div>
                            <div className='ranking-card__content-container__content__podio-container__item__value'>
                                <div className='ranking-card__content-container__content__podio-container__item__value__number title'>
                                    #1
                                </div>
                                <div className='ranking-card__content-container__content__podio-container__item__value__percentage subsubtitle'>
                                    {gestor ? (
                                        formatRecollectionTrim(
                                            showData?.[0]?.RecollectionTrim || 0
                                        )
                                    ) : (
                                        <>
                                            {showData?.[0]?.BZeroIndexTrim?.toLocaleString(
                                                'es-CL',
                                                {
                                                    minimumFractionDigits: 1,
                                                    maximumFractionDigits: 1
                                                }
                                            ) || ''}
                                            %
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='ranking-card__content-container__content__podio-container__item'>
                            <img
                                className='ranking-card__content-container__content__podio-container__item__img'
                                src='/svg-icons/ThirdPlaceIcon.svg'
                            />
                            <div className='ranking-card__content-container__content__podio-container__item__name subsubtitle'>
                                {showData?.[2]?.propertyName ??
                                    showData?.[2]?.propertyUnitName ??
                                    ''}
                            </div>
                            <div className='ranking-card__content-container__content__podio-container__item__value'>
                                <div className='ranking-card__content-container__content__podio-container__item__value__number title'>
                                    #3
                                </div>
                                <div className='ranking-card__content-container__content__podio-container__item__value__percentage subsubtitle'>
                                    {gestor ? (
                                        formatRecollectionTrim(
                                            showData?.[2]?.RecollectionTrim || 0
                                        )
                                    ) : (
                                        <>
                                            {showData?.[2]?.BZeroIndexTrim?.toLocaleString(
                                                'es-CL',
                                                {
                                                    minimumFractionDigits: 1,
                                                    maximumFractionDigits: 1
                                                }
                                            ) || ''}
                                            %
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='ranking-card__content-container__content__list-container'>
                        {showData.length > 3 &&
                            showData
                                .slice(3, Math.min(10, showData.length))
                                .map((data, index) => (
                                    <div
                                        key={data.property ?? data.propertyUnit}
                                        className='ranking-card__content-container__content__list-container__element'
                                    >
                                        <div className='ranking-card__content-container__content__list-container__element__info'>
                                            <div className='ranking-card__content-container__content__list-container__element__info__number title'>
                                                #{index + 4}
                                            </div>
                                            <img
                                                className='ranking-card__content-container__content__list-container__element__info__img'
                                                src='/svg-icons/DotIcon.svg'
                                            />
                                            <div className='ranking-card__content-container__content__list-container__element__info__name subsubtitle'>
                                                {data.propertyName ??
                                                    data.propertyUnitName}
                                            </div>
                                            <img
                                                className='ranking-card__content-container__content__list-container__element__info__img'
                                                src='/svg-icons/DotIcon.svg'
                                            />
                                            <div className='ranking-card__content-container__content__list-container__element__info__percentage subsubtitle'>
                                                {gestor ? (
                                                    formatRecollectionTrim(
                                                        data.RecollectionTrim ||
                                                            0
                                                    )
                                                ) : (
                                                    <>
                                                        {data.BZeroIndexTrim?.toLocaleString(
                                                            'es-CL',
                                                            {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 1
                                                            }
                                                        ) || ''}
                                                        %
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                    </div>
                </div>
                {showData.length < 2 && (
                    <div className='ranking-card__content-container__alert subtitle'>
                        Seleccione más de 1 inmueble/unidad con basura para ver
                        el ranking
                    </div>
                )}
                {appSections.some(item => item.name.includes('Ranking')) && (
                    <button
                        className='button button-body--ranking'
                        onClick={() => navigate('/panel/ranking')}
                    >
                        <div className='button-body--ranking__content'>
                            <div className='button-body--ranking__content__text'>
                                {detailTitle}
                            </div>
                            <img
                                className='button-body--ranking__content__img'
                                src='/svg-icons/Arrows.svg'
                            />
                        </div>
                    </button>
                )}
            </div>
        </div>
    );
};

export default RankingCard;
