import '../settings.scss';
import './calendar.scss';
import { useState } from 'react';
//  <--COMPONENTS--> //
import SubNavBar from '../../../components/layout/main/subnavbar/subnavbar';
/* import Header from '../../../components/header/header';
import ButtonBody from '../../../components/button/button'; */
//  <--INTERFACE--> //
import { lang } from '../../langs';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import * as dateFns from 'date-fns';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es', {
    week: {
        dow: 1,
        doy: 4
    }
});
const localizer = momentLocalizer(moment);

const CalendarPage = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;

    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            pageInvites: { title }
        }
    } = lang;

    const dateToday = new Date();

    const dateLanded: Date = new Date();

    const [dateDisplayed, setDateDisplayed] = useState<Date>(dateLanded);

    const { eventList } = doMath(dateToday, dateLanded);

    return (
        <>
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/CalendarIcon.svg'
                kind='settings'
                option='Calendar'
            ></SubNavBar>
            <div className='settings'>
                <Calendar
                    className='subtitle'
                    onNavigate={newDate => {
                        setDateDisplayed(newDate);
                    }}
                    date={dateDisplayed}
                    localizer={localizer}
                    events={eventList}
                    style={{ height: 576 }}
                    views={['month', 'week', 'agenda']}
                    messages={{
                        next: '>',
                        previous: '<',
                        today: 'Hoy',
                        month: 'Mes',
                        week: 'Semana',
                        day: 'Día'
                    }}
                    formats={{
                        weekdayFormat: date => moment(date).format('dddd')
                    }}
                />
            </div>
        </>
    );
};

export default CalendarPage;

interface TEvent {
    title: string;
    start: Date;
    end: Date;
    allDay: boolean;
}

function doMath(
    dateToday: Date,
    dateLanded: Date
): {
    eventList: TEvent[];
    goodEnoughDate: Date;
} {
    let cursor = dateLanded;

    const eventList = [
        {
            title: 'Retiro vidrio',
            start: dateLanded,
            end: dateLanded,
            allDay: true
        },
        {
            title: 'Today',
            start: dateToday,
            end: dateToday,
            allDay: true
        }
    ];

    while (dateFns.getDay(cursor) !== 5) {
        cursor = dateFns.addDays(cursor, 1);
    }
    // Friday
    eventList.push({
        title: 'Retiro reciclables',
        start: cursor,
        end: cursor,
        allDay: true
    });

    while (dateFns.getDay(cursor) !== 1) {
        cursor = dateFns.addDays(cursor, 1);
    }
    // Monday
    eventList.push({
        title: 'Retiro plástico',
        start: cursor,
        end: cursor,
        allDay: true
    });

    cursor = dateFns.addWeeks(cursor, 1);
    // Monday
    eventList.push({
        title: 'Retiro PET',
        start: cursor,
        end: cursor,
        allDay: true
    });

    cursor = dateFns.addDays(cursor, 4);
    // Friday
    eventList.push({
        title: 'Entrega de bolsas',
        start: cursor,
        end: cursor,
        allDay: true
    });

    cursor = dateFns.addDays(cursor, 2);
    // Sunday
    eventList.push({
        title: 'Retiro de cartón y papel',
        start: cursor,
        end: cursor,
        allDay: true
    });
    cursor = dateFns.addDays(cursor, 1);
    // Monday
    eventList.push({
        title: 'Entrega de bolsas',
        start: cursor,
        end: cursor,
        allDay: true
    });
    cursor = dateFns.addDays(cursor, 1);
    // Tuesday
    eventList.push({
        title: 'Retiro de latas',
        start: cursor,
        end: cursor,
        allDay: true
    });

    return { eventList, goodEnoughDate: cursor };
}
