//  <--COMPONENTS--> //
import { useEffect, useState, useRef, useCallback } from 'react';
import SubNavBar from '../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../components/header/header';
import { DatePicker, ConfigProvider, Tooltip, Checkbox } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import RecolectionCard from '../../../components/dataCenterComponents/recolectioncard/recolectioncard';
import BZeroIndexCard from '../../../components/dataCenterComponents/bzeroIndexCard/bzeroindexcard';
//  <--REDUX--> //
import { useAppSelector } from '../../../store/hooks';
import { MaterialInterface } from '../../../interface';
import { PropertyStoreInterface } from '../../../store/slices/property/property.interface';
import { PropertyUnitStoreInterface } from '../../../store/slices/property-unit/property-unit.interface';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import useWindowDimensions from '../../../config/hooks/useWindowDimentions';
import { HTTP_METHODS } from '../../../config/hooks/useCallApi/constants';
import './evolution.scss';
import { useCallApi } from '../../../config/hooks/useCallApi';
import { FormatData, FormatLabels } from '../../../config/utils/IdbFormatData';
import { indexesPosibilities, initValues, bzeroIndex } from '../data';
import { StackedBarChart } from '../../../components/charts';
import { IDB } from '../../../config/utils/IndexedDb';
import { lang } from '../../langs';
import { NormalizeName, Zfill } from '../../../config/utils/Utilities';
import { ApiDownloadChartsData } from '../../../config/service';
const dbName = process.env.REACT_APP_PUBLIC_INDEXEDDB ?? 'pryma';
const tblName = 'data-center';

const Stacked1 = StackedBarChart;
Stacked1.Config.plugins.legend.display = false;
Stacked1.Config.plugins.legend.position = 'top';
Stacked1.Config.plugins.title.text = '';

const { RangePicker } = DatePicker;

interface LabelInterface {
    date: string;
}

interface MaterialsAvailableInterface {
    label: string;
    id: string;
    position: number;
    selected: boolean;
}

const Evolution = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            pageDataCenter: {
                pageEvolution: {
                    title,
                    downloadDataButton,
                    downloadChartButton,
                    dateRangeTitle
                },
                months
            }
        }
    } = lang;
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        CreateCollection,
        /* AddDataToDb, */
        GetAllDataDb, // this function triggers an effect that updates the data, the parameter that brings the data is ObtainedData
        GetDataDb, // this function triggers an effect that updates the data, the parameter that brings the data is ObtainedData
        ObtainedData
    } = IDB(dbName);
    const { propertyData, selected }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const { selectedUnits }: PropertyUnitStoreInterface = useAppSelector(
        state => state.propertyUnit
    );

    const [filterKinds, setFilterKinds] = useState<MaterialInterface[]>([]);
    const [labels, setLabels] = useState<string[]>([]);
    const [dataIdb, setDataIdb] = useState<any>([]);
    const [colorsDB, setColorsDB] = useState({});
    const [dataApiledChart, setDataApiledChart] = useState<any>(initValues);
    const [dateFilterRange, setDateFilterRange] = useState<string[]>([]);
    const [labelList, setLabelList] = useState<LabelInterface[]>([]);
    const [allLabels, setAllLabels] = useState<string[]>([]);
    const [selectedDateBreakpoints, setSelectedDateBreakpoints] = useState<
        string[]
    >([]);
    const [formattedLabels, setFormattedLabels] = useState<string[]>([]);
    const [defaultStartDate, setDefaultStartDate] = useState<any>();
    const [defaultEndDate, setDefaultEndDate] = useState<any>();
    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [expandOpen, setExpandOpen] = useState<boolean>(false);
    const [materials, setMaterials] = useState<MaterialsAvailableInterface[]>(
        []
    );
    const [removedDatasets, setRemovedDatasets] = useState<any[]>([]);
    const [selectAll, setSelectAll] = useState<boolean>(true);

    const [filter, setFilter] = useState<any>({
        tables: [tblName],
        filters: {
            keys: [],
            values: []
        }
    });

    const ref = useRef<any>(null);

    const DownloadChart = useCallback(() => {
        if (ref.current) {
            const link = document.createElement('a');
            link.download = 'chart.jpg';
            link.href = ref.current.toBase64Image('image/jpg', 1);
            link.click();
        }
    }, []);

    const adjustToEndOfMonth = date => {
        const year = date.year();
        const month = parseInt(date.month()) + 1; // month() devuelve 0-11, por lo que sumamos 1
        const lastDayOfMonth = dayjs(`${year}-${month}`).daysInMonth();
        return dayjs(
            `${year}-${String(month).padStart(2, '0')}-${lastDayOfMonth}`
        ).format('YYYY-MM-DD');
    };

    const DownloadData = async () => {
        const ids =
            selected.length + selectedUnits.length > 0
                ? selected.map(item => item.id)
                : propertyData.map(item => item.id);
        const idsUnits = selectedUnits.map(item => {
            return item.id;
        });
        const idsMaterials = materials
            .filter(item => item.selected)
            .map(item => item.id);
        const ini =
            dateFilterRange && dateFilterRange.length > 0
                ? `${dateFilterRange[0].substring(
                      0,
                      4
                  )}-${dateFilterRange[0].substring(4, 6)}-01`
                : defaultStartDate.format('YYYY-MM-DD');
        const fin =
            dateFilterRange && dateFilterRange.length > 1
                ? adjustToEndOfMonth(
                      dayjs(
                          `${dateFilterRange[1].substring(
                              0,
                              4
                          )}-${dateFilterRange[1].substring(4, 6)}-01`
                      )
                  )
                : adjustToEndOfMonth(defaultEndDate);
        const data = {
            filters: {
                propertyIds: ids,
                units: idsUnits,
                dateRange: {
                    ini,
                    fin
                },
                materials: idsMaterials
            }
        };

        const response = await GetData(
            ApiDownloadChartsData,
            HTTP_METHODS.POST,
            data
        );
        const {
            data: { filepathdownload }
        } = response;
        window.open(filepathdownload, '_blank');
    };

    useEffect(() => {
        setIsMobile(width <= 768);
    }, [width]);

    useEffect(() => {
        const ids = selected.map(item => {
            return item.id;
        });
        const idsUnits = selectedUnits.map(item => {
            return item.id;
        });
        const finalFilter = {
            tables: [tblName],
            filters: {
                keys: ['propertyId'],
                valuesProperty: ids,
                valuesPropertyUnits: idsUnits
            }
        };
        setFilter(finalFilter);
        if (labels.length > 0) {
            if (selectedUnits.length + selected.length >= 1) {
                GetDataDb({ ...finalFilter, dateFilterRange });
            } else {
                GetAllDataDb({
                    tables: [tblName],
                    filters: {
                        keys: [],
                        valuesProperty: []
                    },
                    dateFilterRange
                });
            }
        }
    }, [labels, selected]);

    useEffect(() => {
        const totals: Record<number, number> = {}; // Aquí se almacenarán los totales

        dataApiledChart.datasets.forEach(item => {
            if (item.label !== 'Índice BZero') {
                item.data.forEach((value, index) => {
                    // Convierte el valor a un número y súmalo al total correspondiente
                    totals[index] = (totals[index] || 0) + parseFloat(value);
                });
            }
        });
        // Ahora, calcula el máximo de los totales
        const maxTotal = Math.max(...Object.values(totals));
        if (maxTotal > 1000) {
            Stacked1.AddTicks.title.text = 'Suma acumulada (mil [kg])';
        } else {
            Stacked1.AddTicks.title.text = 'Suma acumulada ([kg])';
        }
        if (!isMobile) {
            Stacked1.Config.plugins.legend.display = false;
        }
        Stacked1.Config.plugins.legend.position = 'bottom';
        Stacked1.Config.plugins.legend.align = 'start'; // Alinea la leyenda a la izquierda
        Stacked1.Config.plugins.title.text = '';
        Stacked1.Config.scales.x.display = true;
        Stacked1.Config.scales.y = {
            ...Stacked1.Config.scales.y,
            ...Stacked1.AddTicks
        };
    }, [dataApiledChart]);

    useEffect(() => {
        if (ObtainedData['data-center']) {
            FormatDataToCharts(
                ObtainedData['data-center'],
                selectedDateBreakpoints
            );
        }
    }, [ObtainedData]);

    const formatLabelsShow = labelsArray => {
        const formatted = labelsArray.map(label => {
            const year = label.substring(0, 4);
            const month = label.substring(4, 6);
            return `${months[parseInt(month) - 1].slice(0, 3)} ${year.slice(
                2,
                4
            )}`;
        });
        setFormattedLabels(formatted);
    };

    const FormatDataToCharts = (data, labelsdata = labels) => {
        if (labelsdata.length === 0) {
            labelsdata = labels;
            formatLabelsShow(labels);
        }
        setDataIdb(data);
        formatLabelsShow(labelsdata);
        const dataFormatedApiled = FormatData({
            data,
            labels: labelsdata,
            chart: 'apiled',
            milestones: 'date',
            dataSetList: filterKinds,
            filter,
            apiledLine: true,
            apiledLineData: bzeroIndex,
            apiledLineOnFilter: ['propertyId'],
            colorsDB
        });

        // Indice BZero y Basura como primer elemento siempre
        dataFormatedApiled.datasets.sort((a, b) => {
            if (a.label === 'Índice BZero') {
                return -1;
            } else if (b.label === 'Índice BZero') {
                return 1;
            } else if (a.label === 'Basura') {
                return -1;
            } else if (b.label === 'Basura') {
                return 1;
            } else {
                return 0;
            }
        });
        const otherDatasets = dataFormatedApiled.datasets.filter(
            dataset =>
                dataset.label !== 'Índice BZero' && dataset.label !== 'Basura'
        );

        const groupedDatasets = {};
        otherDatasets.forEach(dataset => {
            if (!groupedDatasets[dataset.kind]) {
                groupedDatasets[dataset.kind] = [];
            }
            groupedDatasets[dataset.kind].push(dataset);
        });

        const sortedOtherDatasets: any = [];
        Object.keys(groupedDatasets).forEach(kind => {
            sortedOtherDatasets.push(...groupedDatasets[kind]);
        });

        // Actualizar orden de los datasets en dataFormatedApiled
        dataFormatedApiled.datasets = [
            ...dataFormatedApiled.datasets.filter(
                dataset => dataset.label === 'Índice BZero'
            ),
            ...dataFormatedApiled.datasets.filter(
                dataset => dataset.label === 'Basura'
            ),
            ...sortedOtherDatasets
        ];
        GetBZeroIndex(dataFormatedApiled);
        /* } */
    };

    const GetBZeroIndex = dataFormatedApiled => {
        // Calcular Índice BZero para gráfico
        const dataBasura = dataFormatedApiled.datasets.filter(
            item => item.label === 'Basura'
        );
        const dataFinal = dataFormatedApiled.datasets.filter(
            item => item.label !== 'Índice BZero'
        );
        if (dataBasura.length > 0) {
            const indexesArray = new Array(dataBasura[0].data.length).fill(0);
            dataFinal.forEach(obj => {
                obj.data.forEach((value, index) => {
                    indexesArray[index] =
                        parseFloat(indexesArray[index]) + parseFloat(value);
                });
            });

            const sum = Array.from(
                { length: dataBasura[0].data.length },
                () => 0
            );
            dataBasura.forEach(item => {
                item.data.forEach((value, index) => {
                    sum[index] += parseFloat(value);
                });
            });
            sum.forEach((item, index) => {
                indexesArray[index] =
                    (item / parseFloat(indexesArray[index])) * 100;
            });

            const indexBZero = dataFormatedApiled.datasets.findIndex(
                item => item.label === 'Índice BZero'
            );
            // Asignar el valor de indexesArray al atributo data del elemento 'Índice BZero'
            if (indexBZero !== -1) {
                dataFormatedApiled.datasets[indexBZero].data = indexesArray;
            }
        }
        setDataApiledChart(dataFormatedApiled);
        let currentPosition = 0;
        const updatedMaterials = dataFormatedApiled.datasets
            .filter(
                objeto =>
                    objeto.data
                        .map(Number)
                        .reduce(
                            (a, b) => (isNaN(b) ? a : Number(a) + Number(b)),
                            0
                        ) > 0
            )
            .map(objeto => {
                const existingMaterial = materials.find(
                    material => material.id === objeto.id
                );
                return {
                    label: objeto.label,
                    id: objeto.id,
                    position: existingMaterial
                        ? existingMaterial.position
                        : currentPosition++,
                    selected: existingMaterial
                        ? existingMaterial.selected
                        : true
                };
            });
        setMaterials(updatedMaterials);

        const selectedMaterials = updatedMaterials.filter(
            material => material.selected
        );
        const selectedMaterialIds = selectedMaterials.map(
            material => material.id
        );
        // Filtra dataApiledChart según los IDs de los materiales seleccionados
        if (selectedMaterialIds.length > 0) {
            const filteredDatasets = dataFormatedApiled.datasets.filter(
                dataset => selectedMaterialIds.includes(dataset.id)
            );
            const dataFormatedApiledFiltered = {
                labels: dataFormatedApiled.labels,
                datasets: filteredDatasets
            };
            setDataApiledChart(dataFormatedApiledFiltered);
            const removedDatasets = dataFormatedApiled.datasets.filter(
                dataset => !selectedMaterialIds.includes(dataset.id)
            );
            setRemovedDatasets(removedDatasets);
        }
    };

    useEffect(() => {
        CreateCollection(
            [
                {
                    id: 'id',
                    tableName: tblName,
                    indexes: indexesPosibilities
                }
            ],
            2
        );
        CallData();
        if (labels.length > 0) {
            const startDate = dayjs(
                `${labels[0].slice(0, 4)}-${labels[0].slice(4, 6)}-01`
            );
            const endDate = dayjs(
                `${labels[labels.length - 1].slice(0, 4)}-${labels[
                    labels.length - 1
                ].slice(4, 6)}-01`
            ).endOf('month');
            setDefaultStartDate(startDate);
            setDefaultEndDate(endDate);
        }
    }, []);

    const CallData = async () => {
        const filters = localStorage.getItem('filters');
        setFilterKinds(filters ? JSON.parse(filters) : []);
        const labelsData = localStorage.getItem('labels');
        const labels = labelsData ? JSON.parse(labelsData) : null;
        const arrayLabels = FormatLabels(labels, 'date');
        const arrayAllLabels = FormatLabels(labels, 'date', true);
        const colors = localStorage.getItem('colors');
        setColorsDB(colors ? JSON.parse(colors) : []);
        setLabelList(labels);
        setLabels(arrayLabels);
        setAllLabels(arrayAllLabels);
        // SAVE DATA INTO INDEXEDDB
        GetAllDataDb({
            tables: [tblName],
            filters: {
                keys: [],
                valuesProperty: []
            },
            dateFilterRange
        });
    };

    const ChangeDate = date => {
        const ini = `${date[0].$y}${Zfill(parseInt(date[0].$M) + 1, 2)}`;
        const fin = `${date[1].$y}${Zfill(parseInt(date[1].$M) + 1, 2)}`;
        // eslint-disable-next-line array-callback-return
        const newBreakpoints = labelList
            .map(item => {
                if (item.date >= ini && item.date <= fin) {
                    return item.date;
                } else {
                    return '';
                }
            })
            .filter(el => el !== '');
        setSelectedDateBreakpoints(newBreakpoints);
        // FORMATEAR DATA NUEVAMENTE EN BASE A LAS FECHAS SELECCIOANDAS
        FormatDataToCharts(ObtainedData['data-center'], newBreakpoints);
        setDateFilterRange([ini, fin]);
    };

    const ChangeMaterials = (material: MaterialsAvailableInterface) => {
        if (!material.selected) {
            const addedDataset = removedDatasets.find(
                dataset => dataset.id === material.id
            );
            if (addedDataset) {
                setDataApiledChart(prevData => {
                    const datasets = [...prevData.datasets, addedDataset];
                    const sortedDatasets = [...datasets].sort((a, b) =>
                        a.kind.localeCompare(b.kind)
                    );
                    const indexToRemove = sortedDatasets.findIndex(
                        dataset =>
                            dataset.id ===
                            '37240a04-f6c5-6ea0-df0d-9953a5c7596d'
                    );

                    if (indexToRemove !== -1) {
                        const removedDataset = sortedDatasets.splice(
                            indexToRemove,
                            1
                        )[0];
                        sortedDatasets.unshift(removedDataset);
                    }

                    return {
                        ...prevData,
                        datasets: sortedDatasets
                    };
                });

                setRemovedDatasets(prevRemovedDatasets =>
                    prevRemovedDatasets.filter(
                        removedDataset => removedDataset.id !== material.id
                    )
                );
            }
        } else {
            const removedDataset = dataApiledChart.datasets.find(
                dataset => dataset.id === material.id
            );
            if (removedDataset) {
                setRemovedDatasets(prevRemovedDatasets => [
                    ...prevRemovedDatasets,
                    removedDataset
                ]);

                setDataApiledChart(prevData => {
                    const datasets = prevData.datasets.filter(
                        dataset => dataset.id !== material.id
                    );
                    return {
                        ...prevData,
                        datasets
                    };
                });
            }
        }
        const updatedMaterials = materials.map(materialItem =>
            materialItem.id === material.id
                ? { ...materialItem, selected: !materialItem.selected }
                : materialItem
        );
        setMaterials(updatedMaterials);
    };

    const unselectAll = () => {
        if (selectAll) {
            materials.forEach(material => {
                const removedDataset = dataApiledChart.datasets.find(
                    dataset => dataset.id === material.id
                );
                if (removedDataset) {
                    setRemovedDatasets(prevRemovedDatasets => [
                        ...prevRemovedDatasets,
                        removedDataset
                    ]);

                    setDataApiledChart(prevData => {
                        const datasets = prevData.datasets.filter(
                            dataset => dataset.id !== material.id
                        );
                        return {
                            ...prevData,
                            datasets
                        };
                    });
                }
                material.selected = false;
            });
        } else {
            materials.forEach(material => {
                const addedDataset = removedDatasets.find(
                    dataset => dataset.id === material.id
                );
                if (addedDataset) {
                    setDataApiledChart(prevData => {
                        const datasets = [...prevData.datasets, addedDataset];
                        const sortedDatasets = [...datasets].sort((a, b) =>
                            a.kind.localeCompare(b.kind)
                        );
                        const indexToRemove = sortedDatasets.findIndex(
                            dataset =>
                                dataset.id ===
                                '37240a04-f6c5-6ea0-df0d-9953a5c7596d'
                        );

                        if (indexToRemove !== -1) {
                            const removedDataset = sortedDatasets.splice(
                                indexToRemove,
                                1
                            )[0];
                            sortedDatasets.unshift(removedDataset);
                        }

                        return {
                            ...prevData,
                            datasets: sortedDatasets
                        };
                    });

                    setRemovedDatasets(prevRemovedDatasets =>
                        prevRemovedDatasets.filter(
                            removedDataset => removedDataset.id !== material.id
                        )
                    );
                    material.selected = true;
                }
            });
        }

        setSelectAll(!selectAll);
    };

    useEffect(() => {
        if (labels.length > 0) {
            const startDate = dayjs(
                `${labels[0].slice(0, 4)}-${labels[0].slice(4, 6)}-01`
            );
            const endDate = dayjs(
                `${labels[labels.length - 1].slice(0, 4)}-${labels[
                    labels.length - 1
                ].slice(4, 6)}-01`
            ).endOf('month');
            setDefaultStartDate(startDate);
            setDefaultEndDate(endDate);
        }
    }, [labels]);

    const handleScroll = direction => {
        const container = document.querySelector(
            '.evolution__chart-container__filters__materials__filter__list'
        );

        if (container) {
            const scrollAmount = 200;

            if (direction === 'left') {
                container.scrollLeft -= scrollAmount;
            } else {
                container.scrollLeft += scrollAmount;
            }
        }
    };

    useEffect(() => {
        // Verificar si todos los materiales están seleccionados
        const allSelected = materials.every(material => material.selected);
        setSelectAll(allSelected);
    }, [materials]);

    return (
        <>
            <SubNavBar></SubNavBar>
            <div className='evolution'>
                <Header title={{ name: title }} />
                <div className='evolution__chart-container'>
                    <div className='evolution__chart-container__filters'>
                        <div className='evolution__chart-container__filters__materials'>
                            <div className='evolution__chart-container__filters__materials__title subtitle'>
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Checkbox: {
                                                colorPrimary: '#C4C3C5',
                                                colorBorder: '#C4C3C5',
                                                colorText: '#C4C3C5',
                                                colorPrimaryHover: '#C4C3C5'
                                            }
                                        }
                                    }}
                                >
                                    <Checkbox
                                        className='evolution__chart-container__filters__materials__title__checkbox'
                                        checked={selectAll}
                                        onChange={() => unselectAll()}
                                    />
                                    <label htmlFor='selectAll'>
                                        Seleccionar todos
                                    </label>
                                </ConfigProvider>
                            </div>
                            <div className='evolution__chart-container__filters__materials__filter'>
                                <button
                                    className='evolution__chart-container__filters__materials__filter__arrow-btn title'
                                    onClick={() => handleScroll('left')}
                                >
                                    <LeftOutlined
                                        style={{ height: '100%' }}
                                        rev={''}
                                    />
                                </button>
                                <div className='evolution__chart-container__filters__materials__filter__list'>
                                    {materials.map(material => (
                                        <Tooltip
                                            title={
                                                <span
                                                    key={material.id}
                                                    className='main_menu__grid-container__tooltip'
                                                >
                                                    {material.label}
                                                </span>
                                            }
                                            color='#fff'
                                            key={material.id}
                                        >
                                            <button
                                                key={material.id}
                                                onClick={() =>
                                                    ChangeMaterials(material)
                                                }
                                            >
                                                <img
                                                    className={`evolution__chart-container__filters__materials__filter__list__logo${
                                                        !material.selected
                                                            ? '__unselected'
                                                            : ''
                                                    }`}
                                                    src={`/svg-icons/material-icons/${NormalizeName(
                                                        material.label
                                                    )}.svg`}
                                                    alt={material.label}
                                                    key={material.id}
                                                    onError={e => {
                                                        const target =
                                                            e.target as HTMLImageElement;
                                                        target.src =
                                                            '/svg-icons/material-icons/general.svg';
                                                    }}
                                                ></img>
                                            </button>
                                        </Tooltip>
                                    ))}
                                </div>
                                <button
                                    className='evolution__chart-container__filters__materials__filter__arrow-btn title'
                                    onClick={() => handleScroll('right')}
                                >
                                    <RightOutlined rev={''} />
                                </button>
                            </div>
                        </div>
                        <div className='evolution__chart-container__filters__date-filter'>
                            <div className='evolution__chart-container__filters__date-filter__title subtitle'>
                                {dateRangeTitle}
                            </div>
                            {defaultStartDate && (
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            DatePicker: {
                                                colorLink: '#a2a2a3',
                                                colorLinkActive: '#a2a2a3',
                                                colorPrimary: '#a2a2a3',
                                                colorPrimaryBorder: '#a2a2a3',
                                                colorPrimaryHover: '#a2a2a3',
                                                colorBgContainer: '#f3f2f5',
                                                colorBorder: '#fff',
                                                fontFamily: 'Roboto',
                                                colorIcon: '#a2a2a3',
                                                colorText: '#C4C3C5',
                                                colorTextHeading: '#a2a2a3',
                                                colorTextPlaceholder: '#a2a2a3'
                                            }
                                        }
                                    }}
                                >
                                    <RangePicker
                                        className='evolution__chart-container__filters__date-filter__date-picker'
                                        picker='month'
                                        format={'YYYY-MM'}
                                        onChange={ChangeDate}
                                        defaultValue={[
                                            defaultStartDate,
                                            defaultEndDate
                                        ]}
                                    />
                                </ConfigProvider>
                            )}
                            {LoadingData && <LoaderElement />}
                        </div>
                    </div>
                    <div className='evolution__chart-container__chart'>
                        <Stacked1.StackedBarChartComponent
                            refProp={ref}
                            chartData={dataApiledChart}
                            options={{
                                ...Stacked1.Config,
                                scales: {
                                    ...Stacked1.Config.scales,
                                    x: {
                                        ...Stacked1.Config.scales.x,
                                        ticks: {
                                            ...Stacked1.Config.scales.x.ticks,
                                            callback: (
                                                value,
                                                index,
                                                values
                                            ) => {
                                                return formattedLabels[index]; // Mostrar labels formateados si hay propiedades seleccionadas
                                            }
                                        }
                                    }
                                }
                                /* animation: {
                                    delay: context => {
                                        let delay = 0;
                                        if (context.type === 'data') {
                                            delay =
                                                context.dataIndex * 150 +
                                                context.datasetIndex * 50;
                                        }
                                        return delay;
                                    }
                                } */
                            }}
                            dateChangeManager={() => {}}
                        />
                    </div>
                    {isMobile ? (
                        <div className='evolution__chart-container__btns'>
                            <button
                                className='evolution__chart-container__btns__item'
                                onClick={() => setExpandOpen(!expandOpen)}
                            >
                                <img
                                    className='evolution__chart-container__btns__item__icon'
                                    src={`/svg-icons/${
                                        expandOpen
                                            ? 'ContractIcon.svg'
                                            : 'ExpandIcon.svg'
                                    }`}
                                />
                            </button>
                            <button
                                className='evolution__chart-container__btns__item'
                                onClick={DownloadChart}
                            >
                                <img
                                    className='evolution__chart-container__btns__item__icon'
                                    src='/svg-icons/DownloadIcon.svg'
                                />
                            </button>
                        </div>
                    ) : (
                        <div className='evolution__chart-container__btns'>
                            <button
                                type='button'
                                onClick={DownloadChart}
                                className='button button-body--download'
                            >
                                {downloadChartButton}
                            </button>
                            <button
                                type='button'
                                onClick={DownloadData}
                                className='button button-body--download'
                            >
                                {downloadDataButton}
                            </button>
                        </div>
                    )}
                </div>
                <div className='evolution__others'>
                    <RecolectionCard
                        data={dataIdb}
                        filterKinds={filterKinds}
                        filter={filter}
                        labels={allLabels}
                        dateFilterRange={
                            dateFilterRange.length < 2
                                ? [
                                      defaultStartDate?.format('YYYYMM'),
                                      defaultEndDate?.format('YYYYMM')
                                  ]
                                : dateFilterRange
                        }
                    />
                    <BZeroIndexCard data={dataIdb} />
                </div>
            </div>
            {expandOpen && (
                <div className='expandedChart'>
                    <div className='expandedChart__container'>
                        <div
                            className='evolution__chart-container__filters '
                            style={{ flexDirection: 'row', height: '10%' }}
                        >
                            {/* {!isMobile && ( */}
                            <div
                                className='evolution__chart-container__filters__materials'
                                style={{ height: '100%' }}
                            >
                                <div className='evolution__chart-container__filters__materials__filter'>
                                    <button
                                        className='evolution__chart-container__filters__materials__filter__arrow-btn title'
                                        onClick={() => handleScroll('left')}
                                    >
                                        <LeftOutlined
                                            style={{ height: '100%' }}
                                            rev={''}
                                        />
                                    </button>
                                    <div className='evolution__chart-container__filters__materials__filter__list'>
                                        {materials.map(material => (
                                            <Tooltip
                                                title={
                                                    <span
                                                        key={material.id}
                                                        className='main_menu__grid-container__tooltip'
                                                    >
                                                        {material.label}
                                                    </span>
                                                }
                                                color='#fff'
                                                key={material.id}
                                            >
                                                <button
                                                    key={material.id}
                                                    onClick={() =>
                                                        ChangeMaterials(
                                                            material
                                                        )
                                                    }
                                                >
                                                    <img
                                                        className={`evolution__chart-container__filters__materials__filter__logo${
                                                            !material.selected
                                                                ? '__unselected'
                                                                : ''
                                                        }`}
                                                        src={`/svg-icons/material-icons/${NormalizeName(
                                                            material.label
                                                        )}.svg`}
                                                        alt={material.label}
                                                        key={material.id}
                                                    />
                                                </button>
                                            </Tooltip>
                                        ))}
                                    </div>
                                    <button
                                        className='evolution__chart-container__filters__materials__filter__arrow-btn title'
                                        onClick={() => handleScroll('right')}
                                    >
                                        <RightOutlined rev={''} />
                                    </button>
                                </div>
                            </div>
                            <div
                                className='evolution__chart-container__filters__date-filter'
                                style={{ height: '100%' }}
                            >
                                {defaultStartDate && (
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                DatePicker: {
                                                    colorLink: '#a2a2a3',
                                                    colorLinkActive: '#a2a2a3',
                                                    colorPrimary: '#a2a2a3',
                                                    colorPrimaryBorder:
                                                        '#a2a2a3',
                                                    colorPrimaryHover:
                                                        '#a2a2a3',
                                                    colorBgContainer: '#f3f2f5',
                                                    colorBorder: '#fff',
                                                    fontFamily: 'Roboto',
                                                    colorIcon: '#a2a2a3',
                                                    colorText: '#C4C3C5',
                                                    colorTextHeading: '#a2a2a3',
                                                    colorTextPlaceholder:
                                                        '#a2a2a3'
                                                }
                                            }
                                        }}
                                    >
                                        <RangePicker
                                            className='evolution__chart-container__filters__date-filter__date-picker'
                                            style={{ height: '100%' }}
                                            picker='month'
                                            format={'YYYY-MM'}
                                            onChange={ChangeDate}
                                            defaultValue={[
                                                defaultStartDate,
                                                defaultEndDate
                                            ]}
                                        />
                                    </ConfigProvider>
                                )}
                                {LoadingData && <LoaderElement />}
                            </div>
                        </div>
                        <div className='expandedChart__container__chart'>
                            <Stacked1.StackedBarChartComponent
                                refProp={ref}
                                chartData={dataApiledChart}
                                options={{
                                    ...Stacked1.Config,
                                    scales: {
                                        ...Stacked1.Config.scales,
                                        x: {
                                            ...Stacked1.Config.scales.x,
                                            ticks: {
                                                ...Stacked1.Config.scales.x
                                                    .ticks,
                                                callback: (
                                                    value,
                                                    index,
                                                    values
                                                ) => {
                                                    return formattedLabels[
                                                        index
                                                    ]; // Mostrar labels formateados si hay propiedades seleccionadas
                                                }
                                            }
                                        }
                                    }
                                }}
                                dateChangeManager={() => {}}
                            />
                        </div>
                        <div
                            className='evolution__chart-container__btns'
                            style={{ marginRight: 'auto' }}
                        >
                            <button
                                className='evolution__chart-container__btns__item'
                                onClick={() => setExpandOpen(!expandOpen)}
                            >
                                <img
                                    className='evolution__chart-container__btns__item__icon'
                                    src={`/svg-icons/${
                                        expandOpen
                                            ? 'ContractIcon.svg'
                                            : 'ExpandIcon.svg'
                                    }`}
                                />
                            </button>
                            <button
                                className='evolution__chart-container__btns__item'
                                onClick={DownloadChart}
                            >
                                <img
                                    className='evolution__chart-container__btns__item__icon'
                                    src='/svg-icons/DownloadIcon.svg'
                                />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Evolution;
