import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from 'react-router-dom';
/* Pages */
import { ProtectedRoutes } from './protectedRoutes';
import Login from '../../pages/login/login';
import Register from '../../pages/register/register';
import ResetPassword from '../../pages/password/reset-password';
import RequestResetpassword from '../../pages/password/request-reset-password';
import Page404 from '../../pages/404';
import QR from '../../pages/qr/qr';
/* Global components */
import Protected from '../../components/layout/protected';
import MainPrivate from '../../components/layout/main/mainPrivate';
import MainPublic from '../../components/layout/main/mainPublic';

/* Element pages */
function LoginPage() {
    return (
        <MainPublic>
            <Login />
        </MainPublic>
    );
}
function RegisterPage() {
    return (
        <MainPublic>
            <Register />
        </MainPublic>
    );
}
function RequestResetpasswordPage() {
    return (
        <MainPublic>
            <RequestResetpassword />
        </MainPublic>
    );
}
function ResetPasswordPage() {
    return (
        <MainPublic>
            <ResetPassword />
        </MainPublic>
    );
}
function ProtectedPages() {
    return (
        <Protected>
            <MainPrivate>
                <ProtectedRoutes />
            </MainPrivate>
        </Protected>
    );
}
function NotFound() {
    return (
        <MainPublic>
            <Page404 />
        </MainPublic>
    );
}
function QRPage() {
    return (
        <MainPublic>
            <QR />
        </MainPublic>
    );
}

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path='/registration/invite' element={<RegisterPage />} />
                <Route path='/reset-pass/:id' element={<ResetPasswordPage />} />
                <Route
                    path='/request-change-pass'
                    element={<RequestResetpasswordPage />}
                />
                <Route path='/login/:id' element={<LoginPage />} />
                <Route path='/login' element={<LoginPage />} />
                <Route path='/' element={<Navigate to='/login' replace />} />
                <Route path='/qr/:identity' element={<QRPage />} />
                <Route path='/404' element={<NotFound />} />
                <Route path='/panel/*' element={<ProtectedPages />} />
                <Route path='/*' element={<NotFound />} />
            </Routes>
        </Router>
    );
};

export default App;
