import '../ranking.scss';
import { Tooltip } from 'antd';
import { NormalizeName } from '../../../../config/utils/Utilities';

interface Props {
    myProperty: any;
    pdf?: boolean;
}

const MyProperty = ({ myProperty, pdf }: Props) => {
    return (
        <div
            className='ranking__myproperty'
            style={{ height: pdf ? 'auto' : '4%' }}
        >
            {!pdf && (
                <div className='ranking__myproperty__title title'>
                    Estado de mi inmueble/unidad
                </div>
            )}
            <div className='ranking__myproperty__content'>
                <div
                    className={`ranking__myproperty__content__number ${
                        pdf ? 'ranking-pdf' : 'subtitle'
                    }`}
                >
                    #{myProperty?.index}
                </div>
                <div
                    className={`ranking__myproperty__content__data ${
                        pdf ? 'ranking-pdf' : 'subtitle'
                    }`}
                >
                    <div className='ranking__myproperty__content__data__item'>
                        {myProperty?.propertyName ??
                            myProperty?.propertyUnitName}
                    </div>
                    <div className='ranking__myproperty__content__data__item'>
                        <img
                            className='ranking-card__content-container__content__list-container__element__info__img'
                            src={`/svg-icons/${
                                myProperty?.BZeroIndexTrim < 50
                                    ? 'DotIcon.svg'
                                    : myProperty?.BZeroIndexTrim < 75
                                    ? 'DotIconYellow.svg'
                                    : 'DotIconRed.svg'
                            }`}
                        />
                        Índice Bzero:
                        <div className='ranking__myproperty__content__data__item__q'>
                            {myProperty?.BZeroIndexTrim.toLocaleString(
                                'es-CL',
                                {
                                    minimumFractionDigits: 1,
                                    maximumFractionDigits: 1
                                }
                            )}
                            %
                        </div>
                    </div>
                    <div className='ranking__myproperty__content__data__item'>
                        {myProperty?.warning?.[0]?.message ? (
                            <Tooltip
                                title={
                                    <span className='main_menu__grid-container__tooltip'>
                                        {myProperty?.warning?.[0]?.message}
                                    </span>
                                }
                                color='#fff'
                            >
                                <img
                                    className='ranking__list__content__podium__item__header__logo'
                                    src='/svg-icons/RecyclableWarn.svg'
                                />
                            </Tooltip>
                        ) : (
                            <img
                                className='ranking__list__content__podium__item__header__logo'
                                src='/svg-icons/Recyclable.svg'
                            />
                        )}
                        [kg] Reciclables:
                        <div className='ranking__myproperty__content__data__item__q'>
                            {myProperty?.RecyclableTrim.toLocaleString(
                                'es-CL',
                                {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                }
                            )}
                        </div>
                    </div>
                    <div className='ranking__myproperty__content__data__item'>
                        {myProperty?.warning?.[1]?.message ? (
                            <Tooltip
                                title={
                                    <span className='main_menu__grid-container__tooltip'>
                                        {myProperty?.warning?.[1]?.message}
                                    </span>
                                }
                                color='#fff'
                            >
                                <img
                                    className='ranking__list__content__podium__item__header__logo'
                                    src='/svg-icons/OrganicWarn.svg'
                                />
                            </Tooltip>
                        ) : (
                            <img
                                className='ranking__list__content__podium__item__header__logo'
                                src={`/svg-icons/material-icons/${NormalizeName(
                                    'orgánicos'
                                )}.svg`}
                            />
                        )}
                        [kg] Orgánicos:
                        <div className='ranking__myproperty__content__data__item__q'>
                            {myProperty?.OrganicTrim.toLocaleString('es-CL', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })}
                        </div>
                    </div>
                    <div className='ranking__myproperty__content__data__item'>
                        {myProperty?.warning?.[2]?.message ? (
                            <Tooltip
                                title={
                                    <span className='main_menu__grid-container__tooltip'>
                                        {myProperty?.warning?.[2]?.message}
                                    </span>
                                }
                                color='#fff'
                            >
                                <img
                                    className='ranking__list__content__podium__item__header__logo'
                                    src='/svg-icons/TrashWarn.svg'
                                />
                            </Tooltip>
                        ) : (
                            <img
                                className='ranking__list__content__podium__item__header__logo'
                                src={`/svg-icons/material-icons/${NormalizeName(
                                    'Basura'
                                )}.svg`}
                            />
                        )}
                        [kg] Basura:
                        <div className='ranking__myproperty__content__data__item__q'>
                            {myProperty?.TrashTrim.toLocaleString('es-CL', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyProperty;
