import './qr.scss';
import { useState } from 'react';
import { useCallApi } from '../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../config/hooks/useCallApi/constants';
import { ApiQrLink } from '../../config/service';

const CreateQR = () => {
    const { GetData /* , LoadingData, LoaderElement */ } = useCallApi();
    const [url, setUrl] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [desc, setDesc] = useState<string>('');
    const [act, setAct] = useState<boolean>(false);
    const [qrCodeUrl, setQrCodeUrl] = useState<string>('');
    const [dirUrl, setDirUrl] = useState<string>('');

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const values = {
                name,
                description: desc,
                destination: url,
                Active: act
            };
            const response = await GetData(
                ApiQrLink,
                HTTP_METHODS.POST,
                values
            );

            setQrCodeUrl(response.data.qr_code_url);
            setDirUrl(response.data.dir_url);
        } catch (error) {
            console.error('Error generating QR Code:', error);
        }
    };

    return (
        <div className='qr-container'>
            <h1 className='qr-title'>QR Code Generator</h1>
            <form onSubmit={handleSubmit} className='qr-form'>
                <input
                    type='text'
                    value={name}
                    onChange={e => setName(e.target.value)}
                    placeholder='Ingresar nombre de nuevo QR'
                    className='qr-input'
                />
                <input
                    type='text'
                    value={desc}
                    onChange={e => setDesc(e.target.value)}
                    placeholder='Ingresar descripción de nuevo QR'
                    className='qr-input'
                />
                <input
                    type='text'
                    value={url}
                    onChange={e => setUrl(e.target.value)}
                    placeholder='Ingresar URL de pagina a redirigir'
                    className='qr-input'
                />
                <input
                    type='checkbox'
                    checked={act}
                    onChange={e => setAct(e.target.checked)}
                    id='active'
                    name='active'
                />
                <label htmlFor='active'>Active</label>
                <button type='submit' className='qr-button'>
                    Generar codigo QR
                </button>
            </form>
            {dirUrl && (
                <img
                    src={qrCodeUrl}
                    alt='QR Code'
                    className='qrCode'
                    width='200'
                    height='200'
                />
            )}
            {dirUrl && <label>{dirUrl}</label>}
            {qrCodeUrl && <a href={qrCodeUrl}>{qrCodeUrl}</a>}
        </div>
    );
};

export default CreateQR;
