/* import './404.scss'; */
//  <--COMPONENTS--> //
/* import { lang } from '../langs'; */
import LogoComponent from '../../components/logo/logo';
import { ApiQrLinkByIdentity } from '../../config/service';
import { useCallApi } from '../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../config/hooks/useCallApi/constants';
//  <--REDUX--> //
/* import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { useAppSelector } from '../../store/hooks'; */

import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import { useEffect } from 'react';

const QR = () => {
    const { GetData /* , LoadingData, LoaderElement */ } = useCallApi();
    const { identity } = useParams();
    const navigate = useNavigate();

    const CallMainData = async () => {
        if (identity !== undefined) {
            const { data } = await GetData(
                ApiQrLinkByIdentity(identity),
                HTTP_METHODS.GET
            );
            const destination = data.destination;
            if (destination) {
                if (
                    destination.startsWith('http://') ||
                    destination.startsWith('https://')
                ) {
                    window.location.href = destination; // Redirige a una URL externa
                } else {
                    navigate(destination); // Redirige dentro de la aplicación
                }
            }
        }
    };

    useEffect(() => {
        CallMainData();
    }, []);

    return (
        <div className='not-found'>
            <div className='not-found__content'>
                <div className='not-found__content__logo'>
                    <Link to='/panel'>
                        <LogoComponent
                            type='header'
                            image='PRYMA_LOGO_BLANCO.png'
                        />
                    </Link>
                </div>
                <div className='not-found__content__title bigtitle'>
                    {/* {title} */}title
                </div>
                <div className='not-found__content__subtitle title'>
                    {/* {description} */}description
                </div>
                <div className='not-found__content__subtitle title'>
                    {/* {detail} */}detail
                </div>
            </div>
        </div>
    );
};

export default QR;
