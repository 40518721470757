export const ListStorageItems = [
    'expireTime',
    'lang',
    'bzero-2-userdata',
    'bzero-2-deffault-role',
    'bzero-2-user-sections',
    'bzero-2-user-properties',
    'ecoFilters',
    'filters',
    'colors',
    'ecoLabels',
    'labels'
];
