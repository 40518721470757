import './recolectioncard.scss';
import WasteCard from './wasteCardItem/wastecard';
import { useState, useEffect } from 'react';
import { FormatData } from '../../../config/utils/IdbFormatData';
import { MaterialInterface } from '../../../interface/material.interface';
import { lang } from '../../langs';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';
import { NormalizeName } from '../../../config/utils/Utilities';

interface Props {
    data: any;
    filterKinds: MaterialInterface[];
    filter: any;
    labels: string[];
    dateFilterRange: string[];
}

interface WasteCardInterface {
    id: string;
    label: string;
    total: string;
    percentage: string;
    color: string;
}

const RecolectionCard = ({
    data,
    filterKinds,
    filter,
    labels,
    dateFilterRange
}: Props) => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            dataCenterComponents: {
                recolectionCard: {
                    title,
                    subtitle,
                    months,
                    lastMonth,
                    lastQuarter,
                    historic,
                    selectedRange
                }
            }
        }
    } = lang;
    const [array, setArray] = useState<WasteCardInterface[]>([]);
    const [month, setMonth] = useState('');
    const [year, setYear] = useState(0);
    const [period, setPeriod] = useState<number>(0);
    const [dataFormated, setDataFormated] = useState<any>([]);
    const [index, setIndex] = useState<number>(0);

    useEffect(() => {
        const actualDate = new Date();
        const actualDay = actualDate.getDate();
        const dateCard = new Date(actualDate); // Copia de la fecha actual
        if (actualDay > 13) {
            // Mes actual menos 1
            dateCard.setMonth(actualDate.getMonth() - 1);
        } else {
            // Mes actual menos 2
            dateCard.setMonth(actualDate.getMonth() - 2);
        }
        const numberMonth = dateCard.getMonth();
        const yearResult = dateCard.getFullYear();
        const monthResult = months[numberMonth];
        const monthString =
            numberMonth + 1 < 10
                ? '0'.concat((numberMonth + 1).toString())
                : (numberMonth + 1).toString();
        const dateString = yearResult.toString().concat(monthString);
        setMonth(monthResult);
        setYear(yearResult);
        const colors = localStorage.getItem('colors');
        const colorsDB = colors ? JSON.parse(colors) : [];
        if (labels.length > 0 && filterKinds.length > 0) {
            setIndex(labels.indexOf(dateString));
            if (data && data.length > 0) {
                const dataFormated = FormatData({
                    data,
                    labels,
                    chart: 'bar',
                    milestones: 'date',
                    dataSetList: filterKinds,
                    filter,
                    colorsDB
                });
                setDataFormated(dataFormated);
            }
        }
    }, [data, labels, filterKinds]);

    useEffect(() => {
        const arrayvalues: any = [];
        if (period === 0) {
            dataFormated?.datasets?.map(item => {
                const data = {
                    id: item.id,
                    label: item.label,
                    total: parseFloat(item.data[index]).toFixed(0),
                    percentage:
                        (parseFloat(item.data[index]) -
                            parseFloat(item.data[index - 1])) /
                        parseFloat(item.data[index - 1]),
                    color: item.backgroundColor
                };
                arrayvalues.push(data);
                return true;
            });
        } else if (period === 1) {
            const totalSum = dataFormated?.datasets?.reduce((acc, item) => {
                const total =
                    parseFloat(item.data[index]) +
                    parseFloat(item.data[index - 1]) +
                    parseFloat(item.data[index - 2]);
                return parseFloat(acc) + total;
            }, 0);
            dataFormated?.datasets?.map(item => {
                const data = {
                    id: item.id,
                    label: item.label,
                    total: (
                        parseFloat(item.data[index]) +
                        parseFloat(item.data[index - 1]) +
                        parseFloat(item.data[index - 2])
                    ).toFixed(0),
                    percentage:
                        (parseFloat(item.data[index]) +
                            parseFloat(item.data[index - 1]) +
                            parseFloat(item.data[index - 2])) /
                        totalSum,
                    color: item.backgroundColor
                };
                arrayvalues.push(data);
                return true;
            });
        } else if (period === 2) {
            const totalSum = dataFormated?.datasets?.reduce((acc, item) => {
                const total = item.data.reduce(
                    (acc, value) => parseFloat(acc) + parseFloat(value),
                    0
                );
                return parseFloat(acc) + parseFloat(total);
            }, 0);

            dataFormated?.datasets?.map(item => {
                const total = item.data.reduce(
                    (acc, value) => parseFloat(acc) + parseFloat(value),
                    0
                );
                const percentage = total / totalSum;
                const data = {
                    id: item.id,
                    label: item.label,
                    total: total.toFixed(0),
                    percentage: percentage.toFixed(5),
                    color: item.backgroundColor
                };
                arrayvalues.push(data);
                return true;
            });
        } else {
            let startIndex = dataFormated.labels.findIndex(
                label => label === dateFilterRange[0]
            );
            let endIndex = dataFormated.labels.findIndex(
                label => label === dateFilterRange[1]
            );
            if (startIndex === -1) {
                startIndex = 0;
            }
            if (endIndex === -1) {
                endIndex = dataFormated.labels.length - 1;
            }
            const totalSum = dataFormated?.datasets?.reduce((acc, item) => {
                const total = item.data
                    .slice(startIndex, parseInt(endIndex) + 1)
                    .reduce(
                        (subtotal, value) =>
                            parseFloat(subtotal) + parseFloat(value),
                        0
                    );
                return parseFloat(acc) + parseFloat(total);
            }, 0);

            dataFormated?.datasets?.map(item => {
                const dataSlice = item.data.slice(
                    startIndex,
                    parseInt(endIndex) + 1
                );
                const total = dataSlice
                    .reduce(
                        (acc, value) => parseFloat(acc) + parseFloat(value),
                        0
                    )
                    .toFixed(0);
                const data = {
                    id: item.id,
                    label: item.label,
                    total,
                    percentage: total / totalSum,
                    color: item.backgroundColor
                };
                arrayvalues.push(data);
                return true;
            });
        }

        setArray([...arrayvalues].sort((a, b) => b.total - a.total));
    }, [dataFormated, period, dateFilterRange]);

    function getLastQuarter(month, months) {
        const monthIndex = months.indexOf(month);
        if (monthIndex === -1) {
            return 'No válido';
        }
        const lastQuarter =
            monthIndex - 2 < 0
                ? parseInt(months.length) + (monthIndex - 2)
                : monthIndex - 2;
        return `${months[lastQuarter]} -${` `}${month}`;
    }

    return (
        <div className='recolection-card'>
            <div className='recolection-card__titulo-card title'>{title}</div>
            <div className='recolection-card__subtitulo-card subtitle'>
                <div className='recolection-card__subtitulo-card__subtitle subtitle'>
                    {subtitle}:{' '}
                    {period === 0
                        ? month
                        : period === 1
                        ? getLastQuarter(month, months)
                        : period === 2
                        ? historic
                        : selectedRange}{' '}
                    {(period === 0 || period === 1) && year}
                </div>
                <div className='recolection-card__subtitulo-card__date subtitle'>
                    <select
                        className='recolection-card__subtitulo-card__date__select subtitle'
                        onChange={e => setPeriod(e.target.selectedIndex)}
                    >
                        <option className='subtitle'>{lastMonth}</option>
                        <option className='subtitle'>{lastQuarter}</option>
                        <option className='subtitle'>{historic}</option>
                        <option className='subtitle'>{selectedRange}</option>
                    </select>
                </div>
            </div>
            <div className='recolection-card__grid-container'>
                {array.map(item => {
                    return (
                        parseInt(item.total) > 0 && (
                            <WasteCard
                                key={item.id}
                                logo={`/svg-icons/material-icons/${NormalizeName(
                                    item.label
                                )}.svg`}
                                name={item.label}
                                kg={`${item.total} kg`}
                                percentage={item.percentage}
                                color={item.color}
                                period={period}
                            />
                        )
                    );
                })}
            </div>
        </div>
    );
};

export default RecolectionCard;
