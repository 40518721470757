import { StackedBarChart } from '../../../charts';
import { useEffect, useState } from 'react';
import { SupplyInterface } from '../../../../interface';

const StackedDC = StackedBarChart;
StackedDC.Config.plugins.legend.display = false;
StackedDC.Config.plugins.title.text = '';

interface DataItemInterface {
    id: string;
    supply: SupplyInterface;
    capacity: number;
    propertyUnit: string;
    propertyUnitName: string;
    date: string;
    total: number;
}

interface Props {
    data: DataItemInterface[];
    formattedLabels: any;
    labels: any;
}

const ChartFormated = ({ data, labels, formattedLabels }: Props) => {
    const [dataFormated, setDataFormated] = useState<any>({});

    const formatData = () => {
        // Crear un mapa para agrupar resultados por supply id
        const supplyMap = new Map();
        data?.forEach(result => {
            const supplyId = result.supply.id;
            const supplyName = result.supply.name;
            const dateHour = result.date;
            const total = result.total * result.capacity;

            if (!supplyMap.has(supplyId)) {
                supplyMap.set(supplyId, {
                    id: supplyId,
                    label: supplyName,
                    data: Array(labels.length).fill(0) // Inicializar con ceros
                });
            }

            const supplyData = supplyMap.get(supplyId);
            const labelIndex = labels.indexOf(dateHour);
            if (labelIndex !== -1) {
                supplyData.data[labelIndex] =
                    parseInt(supplyData.data[labelIndex]) + total;
            }
        });

        const datasets = Array.from(supplyMap?.values()).map(
            (dataset, index) => {
                const opacity = Math.max(1 - index * 0.15, 0);
                const rgbaColor = `rgba(101, 192, 90, ${opacity})`;

                return {
                    ...dataset,
                    backgroundColor: rgbaColor,
                    borderColor: rgbaColor,
                    borderWidth: 0
                };
            }
        );

        setDataFormated({
            labels,
            datasets: datasets.map(dataset => ({
                ...dataset,
                data: dataset.data.slice(-12) // Asegurar que los datos también tengan un largo de 12
            }))
        });
    };

    useEffect(() => {
        formatData();
    }, [data]);

    useEffect(() => {
        const totals: Record<number, number> = {};
        dataFormated?.datasets?.forEach(item => {
            if (item.label !== 'Índice BZero') {
                item.data.forEach((value, index) => {
                    // Convierte el valor a un número y súmalo al total correspondiente
                    totals[index] = (totals[index] || 0) + parseFloat(value);
                });
            }
        });

        // Ahora, calcula el máximo de los totales
        const maxTotal = Math.max(...Object.values(totals));
        if (maxTotal > 1000) {
            StackedDC.AddTicks.title.text = 'Volumen [mil l]';
        } else {
            StackedDC.AddTicks.title.text = 'Volumen [l]';
        }

        StackedDC.Config.plugins.legend.display = false;
        StackedDC.Config.plugins.title.text = '';
        StackedDC.Config.scales.x.display = true;
        StackedDC.Config.scales.y = {
            ...StackedDC.Config.scales.y,
            ...StackedDC.AddTicks
        };

        // Añadir el título al eje y1
        if (StackedDC.Config.scales.y1) {
            StackedDC.Config.scales.y1.title = {
                display: true,
                align: 'center',
                text: 'Costo mensual bolsas [$]',
                font: {
                    size: 10,
                    weight: 'bold',
                    family: 'Roboto'
                }
            };
        }
    }, [dataFormated]);

    return (
        <StackedDC.StackedBarChartComponent
            chartData={dataFormated}
            options={{
                ...StackedDC.Config,
                scales: {
                    ...StackedDC.Config.scales,
                    x: {
                        ...StackedDC.Config.scales.x,
                        ticks: {
                            ...StackedDC.Config.scales.x.ticks,
                            callback: (value, index, values) => {
                                return formattedLabels[index];
                            }
                        }
                    }
                },
                animation: {
                    delay: context => {
                        let delay = 0;
                        if (context.type === 'data') {
                            delay =
                                context.dataIndex * 150 +
                                context.datasetIndex * 50;
                        }
                        return delay;
                    }
                }
            }}
            dateChangeManager={() => {}}
        />
        /* <div></div> */
    );
};

export default ChartFormated;
