//  <--COMPONENTS--> //
import { useEffect, useState, useRef } from 'react';
import SubNavBar from '../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../components/header/header';
//  <--REDUX--> //
import { useAppSelector } from '../../../store/hooks';
import { PropertyStoreInterface } from '../../../store/slices/property/property.interface';
import { PropertyUnitStoreInterface } from '../../../store/slices/property-unit/property-unit.interface';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { UnitStoreInterface } from '../../../store/slices/unit/unit.interface';
import { PDFExport } from '@progress/kendo-react-pdf';
import './ranking.scss';
import { lang } from '../../langs';
import MyProperty from './components/my-property';
import Disqualified from './components/disqualified';
import RankingList from './components/ranking-list';
import RankingCertificate from './components/certificate';

interface ShowDataItem {
    BZeroIndexMonth: string;
    BZeroIndexTrim: number;
    OrganicMonth: string;
    OrganicTrim: number;
    RecyclableMonth: string;
    RecyclableTrim: number;
    TrashMonth: string;
    TrashTrim: number;
    property: string | null;
    propertyName: string | null;
    propertyUnit: string | null;
    propertyUnitName: string | null;
    warning?: any[];
}

const Ranking = () => {
    const { userData, preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { propertyData, selected }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const { selectedUnits }: PropertyUnitStoreInterface = useAppSelector(
        state => state.propertyUnit
    );
    const { unitData }: UnitStoreInterface = useAppSelector(
        state => state.unit
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            pageDataCenter: {
                months,
                pageRanking: { title }
            }
        }
    } = lang;

    const [podio, setPodio] = useState<any[]>([]);
    const [data, setData] = useState<ShowDataItem[]>([]);
    const [disqualified, setDisqualified] = useState<ShowDataItem[]>([]);
    const [myProperty, setMyProperty] = useState<any>();
    const pdfExportComponent = useRef<PDFExport>(null);
    const [period, setPeriod] = useState<string>('');

    useEffect(() => {
        const date = new Date();
        const year = date.getFullYear();
        const monthIndex = date.getMonth() - 1;
        const lastQuarter =
            monthIndex - 2 < 0
                ? months.length + (monthIndex - 2)
                : monthIndex - 2;

        const formattedMonth = `${months[lastQuarter]} - ${months[monthIndex]} ${year}`;
        setPeriod(formattedMonth);
    }, []);

    useEffect(() => {
        const metrics = localStorage.getItem('metrics');
        const metricsArray = metrics ? JSON.parse(metrics) : [];
        const metricsFloat = metricsArray.ranking.map(item => ({
            ...item,
            BZeroIndexTrim: parseFloat(item.BZeroIndexTrim) * 100,
            RecyclableTrim: parseFloat(item.RecyclableTrim) / 1000,
            OrganicTrim: parseFloat(item.OrganicTrim) / 1000,
            TrashTrim: parseFloat(item.TrashTrim) / 1000
        }));
        const validBZeroIndex = metricsFloat.filter(
            item => item.BZeroIndexTrim > 0
        );
        let sortedMetrics = [...validBZeroIndex].sort(
            (a, b) => a.BZeroIndexTrim - b.BZeroIndexTrim
        );
        if (selected.length + selectedUnits.length > 0) {
            sortedMetrics = sortedMetrics.filter(
                metric =>
                    selected.some(prop => prop.id === metric.property) ||
                    selectedUnits.some(unit => unit.id === metric.propertyUnit)
            );
        }
        setData(sortedMetrics);
        const podio: any = [];
        for (let i = 0; i < Math.min(sortedMetrics.length, 3); i++) {
            podio.push(sortedMetrics[i]);
        }
        if (podio.length >= 2) {
            const temp = podio[0];
            podio[0] = podio[1];
            podio[1] = temp;
        }
        setPodio(podio);

        let metricsDisqulifiedFloat = metricsArray.disqualified.map(item => ({
            ...item,
            BZeroIndexTrim: parseFloat(item.BZeroIndexTrim) * 100,
            RecyclableTrim: parseFloat(item.RecyclableTrim) / 1000,
            OrganicTrim: parseFloat(item.OrganicTrim) / 1000,
            TrashTrim: parseFloat(item.TrashTrim) / 1000
        }));
        if (selected.length + selectedUnits.length > 0) {
            metricsDisqulifiedFloat = metricsDisqulifiedFloat.filter(
                metric =>
                    selected.some(prop => prop.id === metric.property) ||
                    selectedUnits.some(unit => unit.id === metric.propertyUnit)
            );
        }
        setDisqualified(
            [...metricsDisqulifiedFloat].sort(
                (a, b) => a.BZeroIndexTrim - b.BZeroIndexTrim
            )
        );
        const mypropIndex = sortedMetrics.findIndex(
            item =>
                item?.property === propertyData[0]?.id ||
                item?.propertyUnit === unitData[0]?.id
        );
        const myprop =
            metricsFloat.find(item => {
                let found = false;
                propertyData.forEach(property => {
                    if (item?.property === property?.id) {
                        found = true;
                    } else if (property?.propertyUnits) {
                        property.propertyUnits.forEach(unit => {
                            if (
                                item?.property === unit?.id ||
                                item?.propertyUnit === unit?.id
                            ) {
                                found = true;
                            }
                        });
                    }
                });
                return found;
            }) ||
            metricsDisqulifiedFloat.find(item => {
                let found = false;
                propertyData.forEach(property => {
                    if (item?.property === property?.id) {
                        found = true;
                    } else if (property?.propertyUnits) {
                        property.propertyUnits.forEach(unit => {
                            if (
                                item?.property === unit?.id ||
                                item?.propertyUnit === unit?.id
                            ) {
                                found = true;
                            }
                        });
                    }
                });
                return found;
            });
        const mypropWithIndex = {
            ...myprop,
            index: mypropIndex === -1 ? '?' : mypropIndex + 1
        };
        setMyProperty(mypropWithIndex);
    }, [selected, selectedUnits]);

    const DownloadRanking = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
        }
    };

    return (
        <>
            <SubNavBar></SubNavBar>
            <div className='ranking'>
                <Header title={{ name: title }} />
                {(userData.roles[0].name.includes('Property Admin') ||
                    userData.roles[0].name.includes('Unit Admin')) && (
                    <MyProperty myProperty={myProperty} />
                )}
                <RankingList podio={podio} data={data} />
                <Disqualified disqualified={disqualified} />
                <button
                    className='button button-body--download-ranking'
                    onClick={DownloadRanking}
                >
                    Descargar ranking
                </button>
            </div>
            <div className='pdf-container'>
                <PDFExport
                    margin={{ top: '0.8cm', left: 0, right: 0, bottom: 0 }}
                    forcePageBreak={undefined}
                    fileName='ranking.pdf'
                    ref={pdfExportComponent}
                >
                    <RankingCertificate
                        data={data}
                        name={
                            userData?.clientData &&
                            userData.clientData.length > 0 &&
                            userData?.clientData[0].client?.name
                        }
                        period={period}
                        myProperty={
                            (userData.roles[0].name.includes(
                                'Property Admin'
                            ) ||
                                userData.roles[0].name.includes(
                                    'Unit Admin'
                                )) &&
                            myProperty
                        }
                        podio={podio}
                        disqualified={disqualified}
                        clientImage={
                            userData?.clientData &&
                            userData.clientData.length > 0 &&
                            userData?.clientData[0].client?.image
                        }
                    />
                </PDFExport>
            </div>
        </>
    );
};

export default Ranking;
